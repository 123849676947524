import moment from 'moment';
export default {
  isTokenExpiredWithMoment(token) {
    try {
      // Token'ı '.' ile ayır ve payload'u al (ikinci parça)
      const base64Url = token.split('.')[1];
      // Base64'ü decode et
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const payload = JSON.parse(window.atob(base64));
  
      // Token'ın expire olma zamanını al (Unix zaman damgası olarak)
      const expDate = moment.unix(payload.exp).unix(); // Moment nesnesi oluştur ve Unix zaman damgasına çevir

      // Token'ın expire olma zamanından 3 saat çıkar
      const expDateMinus3Hours = expDate - 10800; // 3 saat = 10800 saniye

      // Şu anki zamanı al (Unix zaman damgası olarak)
      const now = moment().unix();
  
      // Şu anki zaman, token'ın expire olma zamanından büyükse, token süresi dolmuştur.
      return now > expDateMinus3Hours;
    } catch (error) {
      // Eğer token çözülemezse, token geçersizdir.
      return true;
    }
  },
  
  isTokenValid(token) {
  
    if(!token) return false;
    return !this.isTokenExpiredWithMoment(token);
  },
  cookie: {
    set(cname, cvalue, exdays = 90) {
    
      const expiresAt = moment().add(exdays, 'days').toDate().toUTCString();
      document.cookie = `${cname}=${cvalue};expires=${expiresAt};path=/`;
    },
    get(cname) {
      const name = `${cname}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },

     delete(cookie,name) {
      cookie.set(name, "", null , null , null, 1);
  }
  },
  global_key: {
    tokenName: 'access_token',
  },
};




<template>
    <slot v-if="!error"></slot>
    <div v-else class="error-warning-container">
        <p> <span>Something went wrong. Please refresh page or logout.</span>
            <span>If the problem persist please contact with the Support Desk. </span>
        </p>
        <p>error: {{ error }}</p>
        <div class="navigator-button">
            <button class="refresh-button" @click="refresh">
                Refresh Page
            </button>

            <button class="logout-button" @click="logout">
                Logout
            </button>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            error: null,
        };
    },
    errorCaptured(err) {
        console.log(err);
        // Hata yakalandığında çalışacak kod
        this.error = err;
        // Hata yönetimini devam ettirmek için false döndür
        return false;
    },
    methods: {
        refresh() {
            window.location.reload();
        },
        async logout() {
            const userInfo = JSON.parse(sessionStorage.getItem("userData")) || {};
            let { ipAdress, location } = this.$store.getters["getUserDetailInfos"];
            let userSessionIntreactTime = sessionStorage.getItem("userSessionIntreactTime") || 0;

            if (Object.keys(userInfo).length > 0) {
                let userlogInfo = {

                    actionName: this.$getUserActionInfo('10003')?.name,
                    actionCode: '10003',
                    userId: String(userInfo?.uesId || ''),
                    ipAdress,
                    location,
                    actionDate: new Date(),
                    userRole: userInfo?.role,
                    userInstitutionId: userInfo?.institutionId,
                    userCampusId: userInfo?.institutionSubSchoolId,
                    userSessionIntreactTime
                }


                try {
                    await this.$axios.post('/user-logs/setUserLogs', userlogInfo);
                } catch (error) {
                    console.log(error);


                }
            }

            this.$store.dispatch("user/logout").then((res) => {
                if (res) window.location.reload();
            });
        },
    },
}
</script>

<style scoped lang="scss">
.error-warning-container {
    width: max-content;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 10vh;
    gap: 1rem;
    border: 2px solid rgba(35, 35, 35, 0.135);
    padding: 2vh 3vw;
    border-radius: 4px;

    p {
        color: rgb(242, 78, 78);
        font-size: 1rem;
        font-weight: 600;
        border: 1px solid rgba(237, 174, 174, 0.739);
        padding: .5rem 1rem;
        border-radius: 4px;
        background: rgb(245, 245, 245);

        span {
            display: block;
            text-align: center;
        }
    }

    .navigator-button {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    button:hover {
        opacity: .8;
    }

    .refresh-button {
        background: #5ecbf7;
        color: white;
        border: none;
        padding: .5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
    }

    .logout-button {
        background: #f7c75e;
        color: white;
        border: none;
        padding: .5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
    }
}
</style>
export default {
    quizTypes : ()=> [
  
        {
            key: "lgs2",
            label: "LGS 2",
          },
   
   

    ]
}
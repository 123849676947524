const domain = 'https://www.gr8vault.com';

export default {

                meta: [
              {
                name: 'description',
                content: `Öğrenme deneyiminizi AI destekli Quiz Maker ile dönüştürün! Hem öğrenciler hem de öğretmenler için tasarlanmış bu platform, quizler, etkileşimli kitaplar ve video içerikler oluşturmanıza olanak tanır. Öğrenciler atanan quizleri çözebilir, AI kullanarak kendi quizlerini üretebilir ve detaylı analiz raporlarına erişebilir. Öğretmenler birden fazla soru hazırlayabilir, quizler oluşturabilir ve öğrenci ilerlemelerini takip edebilir. Kapsamlı LMS'imizle eğitimi yükseltin, etkileşimli ve analitik bir öğrenme ortamı sağlayın.`
              },
              {
                rel: 'icon',
                href: domain + '/favicon_gr8.ico'
              },
              {
                type: 'application/ld+json',
                json: {
                  "@context": "http://schema.org",
                  "@type": "EducationalOrganization",
                  "name": "GR8VAULT",
                  "url": domain,
                  // Diğer ilgili alanlar
                }
              },
              {
                name: 'robots',
                content: 'index, follow'
              },
              {
                name: 'keywords',
                content: 'Yapay zeka destekli quiz oluşturucu, etkileşimli öğrenme platformu, çevrimiçi quiz hazırlayıcı, eğitim teknolojisi araçları, e-öğrenme çözümleri, öğrenci değerlendirme yazılımı, özelleştirilebilir quizler, öğretmen kaynakları, otomatik quiz üretimi, eğitimsel analitik, LMS yazılımı, uzaktan öğrenme araçları, eğitimsel oyunlaştırma, etkileşimli video içerikleri, çevrimiçi öğrenme yönetim sistemi'
            },
              // Open Graph Etiketleri
              {
                property: 'og:title',
                content: 'GR8Vault - Interaktif Quiz Maker - Öğrenciler ve Öğretmenler İçin Yapay Zeka Destekli Eğitim Platformu'
              },
              {
                property: 'og:description',
                content: 'AI destekli Quiz Maker ile öğrenme deneyiminizi dönüştürün! Öğrenciler ve öğretmenler için etkileşimli quizler, kitaplar ve video içerikler oluşturun. Kişiselleştirilmiş öğrenme yoluyla eğitimi keşfedin.'
              },
              {
                property: 'og:type',
                content: 'website'
              },
              
              {
                property: 'og:url',
                content: domain
              },
              {
                property: 'og:image',
                content: domain +'/icons/gr8vault_bilfen_icon.png'
              },
            
              // Ekstra Etiketler
              {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1'
              },
              {
                name: 'author',
                content: 'UES - Sınırsız Eğitim Hizmetleri'
              },
              {
                name: 'language',
                content: 'Türkçe'
              },
            ],
            link: [
              {
                rel: 'canonical',
                href: domain
              }
            ]
        
        }
      
      

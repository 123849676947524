
import axios from '@/services';
import tools from '@/tools';
const defaultState = {
  userInfo:{},
  teacherStatInfo:{},
  studentStatInfo:{},
  adminInstituInfos :[],
  token:null,
  loggedOut:false,
  userListFilterDataSet:{
    schoolIds:[],
    campusIds:[],
    classesList:[],
    roles:[],
  },
  fetchAllUserFilter:{
    schoolId:'',
    campusId:'',
    selectedClasses:[],
    role:'',
    
  },
  userListDatas:[],
  totalUserCount:0

  
}

let userSessionTimer = null;


export default {
  namespaced: true,
  state: defaultState,
  mutations: {

    setTotalUserCount(state,totalCount){
      state.totalUserCount = totalCount
    },
    setUserListTableDatas(state,users){
      state.userListDatas = users
    },
    setAllUserFetchedFilter(state,filter){
      const {propertyName, value} = filter
      state.fetchAllUserFilter[propertyName] = value;  
      if(propertyName === 'schoolId'){
        state.fetchAllUserFilter.campusId = '';
        state.fetchAllUserFilter.selectedClasses = [];
        state.fetchAllUserFilter.role = '';

        state.userListFilterDataSet.roles = []; 
      }
      else if(propertyName === 'campusId'){
        state.fetchAllUserFilter.selectedClasses = [];
        state.fetchAllUserFilter.role = '';
        state.userListFilterDataSet.roles = []; 

      }
      else if(propertyName === 'role'){
        state.fetchAllUserFilter.selectedClasses = [];
      }
    },
    resetAllUserFetchedFilter(state){
      state.fetchAllUserFilter = {
        schoolId:'',
        campusId:'',
        selectedClasses:[],
        role:'',
        
      } 

      state.userListDatas = [];
      state.totalUserCount = 0;
    },

    setUserListFilterDataSet(state,filterDataSet){
      state.userListFilterDataSet = filterDataSet;
    },

    resetUserListFilterDataSet(state){
      state.userListFilterDataSet = {
        schoolIds:[],
        campusIds:[],
        classesList:[],
        roles:[],
      }


    },
    resetUserState(state){
      state = defaultState;

    },
    setUserInfo(state,info){
      state.userInfo = info;
    },
    setToken(state,token){
      state.token = token;
    },
    setTeacherStat(state,stat){
      state.teacherStatInfo = stat
    },
    setStudentStat(state,stat){
      state.studentStatInfo = stat
    },
    setAdminInstituInfos(state,infos){
      state.adminInstituInfos = infos;
    },
    setLoggedOut(state,status){
      state.loggedOut = status
    },
    recordUserSystemInteractionTime() {
      userSessionTimer =  setInterval(() => {
        const userSessionIntreactTime =  +(sessionStorage.getItem("userSessionIntreactTime") || 0) + 1;
        sessionStorage.setItem("userSessionIntreactTime", userSessionIntreactTime);

       }, 1000);
    },
  },
  actions: {

  
     login({commit},{role,classNames,uesId, firstName,lastName}){

      try {
      return  axios().post("user/login", {
          role,
          classNames,
          firstName,
          lastName,
          uesId
      })
      .then(({ token,user,status_code,isUserInfoChanged }) => {
       
        if(status_code == 200) {
          commit('setToken',token);
          tools.cookie.set("authToken", token);
          const userData = JSON.stringify(user)
         sessionStorage.setItem("userData", userData);
         commit('setUserInfo',user);
       
          
        }
        

        return  {status_code,user,isUserInfoChanged}
      
     
          
      })
      .catch((e) => {
          alert(e || "Internal Server Error");
          return false
      });

      } catch (error) {
        throw new Error(error);
      }

  

    
    },

    adminLogin({commit},{role,username,password}){

      try {
      return  axios().post("user/admin-login", {
        role,username,password
      })
      .then(({ token = null,user,status_code, message = ''}) => {

        if(status_code == 200) {
          commit('setToken',token);
          tools.cookie.set("authToken", token);
          const userData = JSON.stringify(user)
         sessionStorage.setItem("userData", userData);
         commit('setUserInfo',user);

          
        }
        

        return  {status_code,user,message}
      
     
          
      })
      .catch((e) => {
         
          return {status:false,alert:e}
      });

      } catch (error) {
        return {status:false,alert:error}
      }

  

    
    },

    logout({commit,state}){
        // Logout işlemlerini yapın
        
        if ('BroadcastChannel' in window) {
      if(!state.loggedOut){

          // Yeni bir BroadcastChannel oluştur
          const logoutChannel = new BroadcastChannel('logout');
  
          // Bir mesaj gönder
          logoutChannel.postMessage('User has logged out');
      }
        }
      commit('setLoggedOut', true);
      commit('setToken',null);
      commit('resetUserState')
      commit('question/resetQuestionState', null, { root: true });
      commit('quiz/resetQuizState', null, { root: true });
      commit('studentQuiz/resetStudentQuizState', null, { root: true });
      commit('resetIndexState', null, { root: true });


      
      tools.cookie.delete(tools.cookie,"authToken")
      const sessionId = process.env.VUE_APP_SESSION_REF;

      function clearSessionStorageExcept(keyToPreserve) {
        // Tüm session storage key'lerini al
        const keys = Object.keys(sessionStorage);
      
        // Her key için
        keys.forEach(key => {
          // İstisna key dışındakileri sil
          if (key !== keyToPreserve) {
            sessionStorage.removeItem(key);
          }
        });
      }
      
      // Kullanımı: 'userData' hariç tüm sessionStorage key'lerini sil
      clearSessionStorageExcept(sessionId);
      
      clearInterval(userSessionTimer);

     
      return true;
    },


    async getAdminInstituInfos({commit}){

      try {
        let {status_code,result} = await axios().post('user/get-institu-infos');
        if(status_code === 200){
          commit('setAdminInstituInfos',result)
        }
  
        return true;
      } catch (error) {
        alert(error);
        return true
      }
  

    },
    createNewUser({commit},userInfo){

    return  axios().post("/user/create-user",userInfo).then(({ token,user,status_code })=>{
        if(status_code == 200){
         
          tools.cookie.set("authToken", token);
          commit('setToken',token);
          let {classNames:className,role, institutionName, subSchoolName, username,  uesId, name, lastName,institutionSubSchoolId, institutionId} = user;
          let userModified = {};

          if(role==="student"){

            userModified = {
              institutionSubSchoolId,
              institutionId,
              className:className[0],
              userSubDetail:{
              institutionName,
              subSchoolName
              },
              role,
              studentName:name,studentLastName:lastName,uesId,username
            } 
          }
          else {
            userModified = {
              classes:className,
              institutionSubSchoolId,
              institutionId,
              userSubDetail:{
              institutionName,
              subSchoolName
              },
              role,
              teacherName:name,teacherLastName:lastName,uesId,username
            } 
          }
      
          const userData = JSON.stringify(userModified)
          
          sessionStorage.setItem("userData", userData);
         
          return  {status_code,role}
        }
        return {status_code};
     
      }).catch(e=>{

        alert(e.response.data.message || "Internal Server Error");
        return false

      })

    },


    async checkUserInfoChanged({commit},userData){
      
      return true;

    },

    async getTeacherStatistic({commit},teacherUesId){
      try {

        let {status_code,teacherStatInfo} = await axios().post("user/teacher-statistic",{uesId:teacherUesId});
        if(status_code === 200){
          commit('setTeacherStat',teacherStatInfo);
        }
        return true
        
      } catch (error) {
        
        alert(error);
        return false
        
      }
      
    },



    async getStudentStatistic({commit},{uesId,studentAllowedPlatforms}){
      try {

        let {status_code,studentStatInfo} = await axios().post("user/student-statistic",{uesId,studentAllowedPlatforms});
        if(status_code === 200){
          commit('setStudentStat',studentStatInfo)
        }
        return true
        
      } catch (error) {
        
        alert(error);
        return false
        
      }
      
    },

    async getUserListFilterBoxDataSet({commit},selectedFilterDataSet){
      try {
        const {status_code, userListFilterDataSet} = await axios().post("user/get-user-list-filter-box-data-set",selectedFilterDataSet);
        if(status_code === 200){
          commit('setUserListFilterDataSet',userListFilterDataSet)
        }
      } catch (error) {
        console.log(error)
      }
    
      

    },

    async getUserListDatas({commit,state,rootGetters}){
      try {
        const userListFilters = state.fetchAllUserFilter;
        const paginateIndex = rootGetters['getPaginationIndex'].currentIndex || 1;
        const {status_code, selectedUsers = [], totalUserCount} = await axios().post("user/get-user-list-datas",{...userListFilters,paginateIndex});
        if(status_code === 200){
          commit('setUserListTableDatas',selectedUsers);
          commit('setTotalUserCount',totalUserCount)
        }
        return true

      } catch (error) {
        console.log(error)
        return false;
      }
    },

    async userDelete({state},{uesId,currentUserRole}){
      try {
        const response = await axios().post("user/user-delete",{uesId,role:currentUserRole});
        if(response.status_code === 200){
          const deletedUserId = uesId;
          state.userListDatas = state.userListDatas.filter(({uesId})=> uesId !== deletedUserId)  
        }
        return true;
      }
      catch (error) {
        console.log(error);
        return false;
      }
    }


  },

  getters: {

    getUserListDatas(state){
      return state.userListDatas;
    },
    getTotalUserCount(state){
      return state.totalUserCount
    },

    getAllUserFilter(state){
      return state.fetchAllUserFilter
    },
    getUserListFilterBoxDataSet(state){
      return state.userListFilterDataSet
    },
      getUserRole(state){
        return state.userInfo.role;
      },
      getUserInfo(state){
        return state.userInfo;
      },
      getTeacherStat(state){
        return state.teacherStatInfo 
      },
      getStudentStat(state){
        return state.studentStatInfo 
      },
      getAdminInstituInfos(state){
        return state.adminInstituInfos;
      },
      getLoggedOut(state){
        return state.loggedOut
      }
  },
}


let domainName =  window?.location?.hostname || 'localhost';
const activeDomains = ['www.myeduquiz.com','myeduquiz.com','www.gr8vault.com','gr8vault.com','lgsxxlarge.com'
 ,'www.lgsxxlarge.com','myeduconcept.com','www.myeduconcept.com'];
if(!activeDomains.includes(domainName)){

domainName = 'www.myeduquiz.com'
}
else{
if(!domainName.includes('www.')){
 domainName = 'www.' + domainName;
}

}

export default domainName;
const domain = 'https://www.gr8vault.com';

export default {

                meta: [
              {
                name: 'description',
                content: `Revolutionize your learning experience with our AI-powered Quiz Maker! Tailored for both students and teachers, this platform allows the creation of quizzes, interactive books, and video content. Students can solve assigned quizzes, generate their own using AI, and access detailed analysis reports. Teachers can craft multiple questions, assemble quizzes, and monitor student progress. Elevate education with our comprehensive LMS, fostering an engaging and analytical learning environment.`
              },
              {
                rel: 'icon',
                href: domain + '/favicon_gr8.ico'
              },
              {
                type: 'application/ld+json',
                json: {
                  "@context": "http://schema.org",
                  "@type": "EducationalOrganization",
                  "name": "GR8VAULT",
                  "url": domain,
                  // Diğer ilgili alanlar
                }
              },
              {
                name: 'robots',
                content: 'index, follow'
              },
              {
                name: 'keywords',
                content: 'AI-powered quiz maker, interactive learning platform, online quiz creator, education technology tools, e-learning solutions, student assessment software, customizable quizzes, teacher resources, automated quiz generation, educational analytics, LMS software, remote learning tools, educational gamification, interactive video content, online learning management system'
            },
              // Open Graph Etiketleri
              {
                property: 'og:title',
                content: 'Interactive Quiz Maker - AI-Powered Education Platform for Students and Teachers'
              },
              {
                property: 'og:description',
                content: 'Transform your learning experience with our AI-powered Quiz Maker! Create interactive quizzes, books, and video content for both students and teachers. Discover education through personalized learning.'
              },
              {
                property: 'og:type',
                content: 'website'
              },
              
              {
                property: 'og:url',
                content: domain
              },
              {
                property: 'og:image',
                content: domain +'/icons/gr8vault_bilfen_icon.png'
              },
            
              // Ekstra Etiketler
              {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1'
              },
              {
                name: 'author',
                content: 'UES - Unlimited Education Services'
              },
              {
                name: 'language',
                content: 'English'
              },
            ],
            link: [
              {
                rel: 'canonical',
                href: domain
              }
            ]
        
        }
      
      

<template>
    <div class="filter-not-full-box-container">
     
         <img class="filter-not-full-icon" src="/icons/general_icons/filter_not_full.png" alt="not_filter_full.icon">
 
     
     <p class="messages"> {{ responseStatusMessages }} </p> 
    </div>
 
 </template>
 
 <script>
 export default {
 props:{
   responseStatusMessages:{
       type:String,
       default:''
   }
 }
 }
 </script>
 
 <style scoped lang="scss">
 
 .filter-not-full-box-container {
     width: 100%;
     min-width: 450px;
     height: 100%;
     display: flex;
     flex-flow: row nowrap;
     align-items: center;
     justify-content: center;
     padding-left: 10px;
     gap: 3%;
     background: rgba(240, 240, 243, 0.87);
     border: 1px solid #97979754;

 
 }
 
 .filter-not-full-icon {
 height: 58px;
 width: 58px;
 
 filter: drop-shadow(0px 0px 1px #ff0303d8);
 
 
 }
 
 .messages {
    width: max-content;
     font-size: .80rem;
     font-weight: bold;
     color: #0d0f27b9;
 }
 </style>
import { createWebHashHistory, createRouter } from "vue-router";
import routes from './routes';
import store from '../store';
import tools from '@/tools';


const router= createRouter({
  history:createWebHashHistory(),
  routes
})

// eslint-disable-next-line
function getDeepRouteByNames(routeNames, routes) {
  const routeName = routeNames.shift();
  if (routeName) {
    const searchRoutes = routes.find(x => x.name === routeName).children
      ? routes.find(x => x.name === routeName).children
      : routes.find(x => x.name === routeName);
    if (routeNames.length > 0) {
      return getDeepRouteByNames(routeNames, searchRoutes);
      // eslint-disable-next-line
    } else {
      return searchRoutes.roles ? searchRoutes.roles : [];
    }
  }
}

function roleCheck(roles, authoritys) {
  for (const id of roles) {
    return authoritys.find(x => x.Yetki_Id === id).YetkiVar === 1 ? true : false;
  }
}

router.beforeEach(async (to, from, next) => {
  const token = tools.cookie.get('authToken');
  const isLms = to.path.includes('/lms');
  const isUserDataExist = sessionStorage.getItem('userData');
  // LMS sayfasına girilmek isteniyor ve token yoksa ya da bozuksa logine yönlendir.
  if (isLms && !token && !isUserDataExist) {
    
    router.push({ path:'/login/user' });
  }

  if(isLms && token && !isUserDataExist){
    router.push({ path:'/login/user' });
  }
  // Ve o route da roles diye bir field varsa
  // senin rolteType'ın o arrayin içinde var mı bak
  // yoksa gitmesine izin verme (YETKİ KONTROLÜ)
  // Gitceğin route lms routenunun içinde ise
  const routeNames = to.matched.map(x => x.name);
  const routeRoles = getDeepRouteByNames(routeNames, routes);
  const userAuthority = store.state.user.authority;
  if (routeRoles && routeRoles.length > 0 && !roleCheck(routeRoles, userAuthority)) {
    next({ path: from.path });
  } else if (routeRoles) {
    next();
  } else {
    next({  path:'/login/user' });
  }
});
export default router;




function getQuizTypes({role = 'super-admin'}) {
const userData = JSON.parse(sessionStorage.getItem("userData"));
const institutionId = userData?.institutionId || '';
const campusId = userData?.institutionSubSchoolId || '';
const currentUserId = userData?.uesId || '';
const onlyConceptStandardShowUser = process.env.VUE_APP_ONLY_CONCEPT_STANDARD_SHOW_USER || ''; 
const bilfenId = process.env.VUE_APP_BILFEN_ID || '';
const uesId = process.env.VUE_APP_TEKNOPARK_ID || '';
const uesCollageSchool = process.env.VUE_APP_UES_COLLEGE_ID || null;
const allowedSchoolAllStandards = [bilfenId,uesId,uesCollageSchool];
const assessmentCampusId = process.env.VUE_APP_ASSESSMENT_CAMPUS_ID || '';
const conceptSchoolId = process.env.VUE_APP_CONCEPT_SCHOOL_ID  || '' ;
const unAllowedCampusToSeeAll = [assessmentCampusId]

let allowedQuizTypes = [];
if(allowedSchoolAllStandards.includes(institutionId) && !unAllowedCampusToSeeAll.includes(campusId)){
    allowedQuizTypes = [
        {
            key:'mixed',
            label:'MIXED'
        },
        {
            key:'lgs',
            label:'LGS'
        },
        {
            key: "lgs2",
            label: "LGS 2",
          },
        {
            key:'kw3',
            label:'KW3'
        },
        {
            key:'cefr',
            label:'CEFR'
        }
    ]
}
else if(currentUserId  == onlyConceptStandardShowUser || conceptSchoolId == institutionId || assessmentCampusId == campusId){
    allowedQuizTypes = [

        {
            key:'cefr',
            label:'CEFR'
        }
    ]
}

else {
    allowedQuizTypes = [
        {
            key: "lgs2",
            label: "LGS 2",
          },
          {
            key: "lgs",
            label: "LGS",
          },
    ]
}
return allowedQuizTypes ;
 }


export default {
    quizTypes : getQuizTypes
}
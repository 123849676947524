
import { createStore } from 'vuex'
import user from "./modules/user";
import question from './modules/question';
import quiz from "./modules/quiz"
import studentQuiz from './modules/studentQuiz';
import VuexPersistence from 'vuex-persist';
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import {v4} from 'uuid';
const defaultState = {
  isResponseFetched:false,
  paginationIndex:null,
  modalStatus:false,
  domainName:'www.myeduquiz.com',
  userSchoolAndStandards:{},
  excelDatas:{},
  getAnalyzesData:{},
  selectedAnalyzesTypes:"",
  analyzesTotalResultCount:0,
  userDetailInfos:{
    location:{
      latitude:0,
      longitude:0

     },
    ipAdress:'0.0.0.0'
  }
}

const cookieName = process.env.VUE_APP_COOKIE_NAME;

const currentSession = process.env.VUE_APP_SESSION_REF;

const storageKey = currentSession;


// Get the encryption token from cookie or generate a new one.
const encryptionToken = Cookie.get(cookieName) || v4();

// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, { secure: true, expires: 180 });

const vuexLocal = new VuexPersistence({
  key: storageKey,
  storage:{
    getItem: () => {
      // Get the store from local storage.
      const store = window.sessionStorage.getItem(storageKey);

      if (store) {
        try {
          // Decrypt the store retrieved from local storage
          // using our encryption token stored in cookies.
          const bytes = Crypto.AES.decrypt(store, encryptionToken);

          return JSON.parse(bytes.toString(Crypto.enc.Utf8));
        } catch (e) {
          // The store will be reset if decryption fails.
          window.sessionStorage.removeItem(storageKey);
        }
      }

      return null;
    },
    setItem: (key, value) => {
      // Encrypt the store using our encryption token stored in cookies.
      const store = Crypto.AES.encrypt(value, encryptionToken).toString();

      // Save the encrypted store in local storage.
      return window.sessionStorage.setItem(storageKey, store);
    },
    removeItem: () => window.sessionStorage.removeItem(storageKey),
  },
  reducer(val){
    
    if(val.user.token == null){ 
      return {}
    }
   
return val
    
  
  }
});

const store =   createStore({
  plugins: [vuexLocal.plugin],
  state:defaultState,
  mutations:{
    resetIndexState(state){
      state = defaultState;

    },
    setResponseFetched(state,status){
      state.isResponseFetched = status;
    },
 
    setUserSchoolAndStandard(state,schoolAndStandards){
      state.userSchoolAndStandards = schoolAndStandards;
    },
    setModalStatus(state,status=false){
      
      state.modalStatus = status;
    },
    setUserDetailInfos(state,{key,value}){
      state.userDetailInfos[key] = value;
    },
    setExcelStage(state,datas){
      state.excelDatas = datas;
    },
    setDomainName(state,name){
      state.domainName = name;
    },
      
    setPaginationIndex(state,index){
     
      if('object' == typeof index){
        state.paginationIndex = index;
      }
   
     
    },

    setSelectedAnalyzesType(state,type){
      state.selectedAnalyzesTypes = type;
    },

    setAnalyzesTotalResultCount(state,count){
      state.analyzesTotalResultCount = count;
    },

    setAnalyzesFilter(state,analyzesFields){
      if(Object.keys(analyzesFields).length){
        for(let propertyName in analyzesFields){
          state.getAnalyzesData[propertyName] = analyzesFields[propertyName];
        }
    }
  },
    resetAnalyzesData(state,deletedFields = []){
      if(deletedFields.length){
        for(let propertyName of deletedFields){
          delete state.getAnalyzesData[propertyName];
        }
      }
      else {
        state.getAnalyzesData = {};
      }
      
    }
  },
  getters:{
    getResponseFetchedStatus(state){
      return state.isResponseFetched;
    },

    getAnalyzesTotalResultCount(state){
      return state.analyzesTotalResultCount;
    },
    getUserDetailInfos(state){
      return state.userDetailInfos;
    },
    getPaginationIndex(state){
     
      return state.paginationIndex ;
    },
    getExcelDatas(state){
      return state.excelDatas;
    },
    getModalStatus(state){
      return state.modalStatus;
    },
    getDomainName(state){
      return state.domainName;
    },
    getUserSchoolAndStandards(state){
      return state.userSchoolAndStandards;
    },

    getAnalyzesFilterDatas(state){
      return state.getAnalyzesData;
    },
    getSelectedAnalyzesType(state){
      return state.selectedAnalyzesTypes;
    }

  },
  modules: {
    user,
    question,
    quiz,
    studentQuiz
  },
});





export default store;
<template>
   <div class="not-found-box-container" v-if="isTimerActive">
    
        <img class="not-found-icon" src="/icons/general_icons/not_found_icon.png" alt="not.found.icon">

    
    <p class="messages"> {{ responseStatusMessages }} </p> 
   </div>

</template>

<script>
export default {
props:{
  responseStatusMessages:{
      type:String,
      default:''
  },
  timer:{
      type:Number,
      default:0
  }
},
data(){
    return {
        isTimerActive:false
    }
},
created(){
    if(this.timer > 0){
        setTimeout(() => {
            this.isTimerActive = true

        }, this.timer);
    }
    else {
        this.isTimerActive = true;
    }

}
}
</script>

<style scoped lang="scss">

.not-found-box-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: 2%;
    border: 1px solid #97979754;



}

.not-found-icon {
height: 58px;
width: 58px;

filter: drop-shadow(0px 0px 1px #ff0303d8);


}

.messages {
   width: max-content;
    font-size: .85rem;
    font-weight: bold;
    color: #0d0f27e1;
    margin-top: 10px;
}
</style>

import cefrStandard from "./cefrStandard";

const standardTypeCEFR = 'CEFR';

const getMyStandard = (standardType,standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef) => {

 
    if(standardTypeCEFR === standardType){
        cefrStandard(standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef);
    }


}

export default getMyStandard;


let lang = document.documentElement.lang;
const activeLangs = ['en', 'tr'];

if (!activeLangs.includes(lang)) {
    lang = activeLangs[0];
}
import seoSettingRulesByLang from './seo_rules_by_languages/indexSeoRulesByLanguage';
const seoSettingRules = ()=>{

    return {
        title:'MyEduConcept',

        ...seoSettingRulesByLang[lang]

    }
}


export default seoSettingRules;

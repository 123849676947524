const allowedRoles = ['student','teacher','campusadmin','schooladmin','super-admin'];
const bilfenSchoolId = process?.env?.VUE_APP_UESLMS_BILFEN_ID ?? '*';
const allowedSchools = [bilfenSchoolId]; // Only Bilfen user can access on this domain. As default to fail cases setted "*" for all schools.
const uesAuthKey = process.env.VUE_APP_UESLMS_AUTH_KEY || null;

let allowedPlatformsToStudent = [
       'my-edu-quiz',
       
]

const allowedUserRules = {
       roles: allowedRoles,
       schools: allowedSchools,
       allowedPlatformsToStudent,
       uesAuthKey 
}

export default allowedUserRules;
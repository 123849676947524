<template>
  <div class="warning-container" >

  <span> <i class="fa-solid fa-triangle-exclamation"></i></span>
  <span v-html="warningMessage"> </span>

        
         <span class="close" @click="$emit('closeWarning')"
      ><i class="fa fa-times" aria-hidden="true"></i
    >
    </span>

  </div>

   

</template>

<script>
export default {
    props:{
        warningMessage: String,
        exitTime :{
            type: Number,
            default: 4000
        }
    },

    created(){
      
        setTimeout(() => {
             this.$emit('closeWarning') ;
        }, this.exitTime);
    }
}
</script>

<style scoped lang="scss">

.warning-container{

    position: fixed;
    top: 5%;
    right: 2%;
    min-height: 110px;
    height: auto;
    width: 300px;
    display: flex;
    align-items: center;
    background: #d33232e8;
    color: white;
    font-weight: 650;
    text-align:justify;
    justify-content: center;
    padding: 10px;
    gap: 8px;
    border-radius: 8px;
    box-shadow:2px 2px 2px  rgba(245, 3, 3, 0.233) ;
    font-size: .85rem;

}
.fa-triangle-exclamation {
font-size:2rem;
color: yellow;
}

.close {
  position: absolute;
  top: 4px;
  right: 4px;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5 all ease-in-out;
  color:#352d57fb ;

  &:hover {
   
    box-shadow: 0.5px 1px 0.5px rgba(225, 230, 225, 0.829);
  }
}
</style>
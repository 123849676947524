
import lgs2Standard from './lgs2Standard';

const standardTypeLGS2 = 'LGS2';

const getMyStandard = (standardType,standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef) => {

    if(standardTypeLGS2 === standardType){
        lgs2Standard(standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef);
    }
  

}

export default getMyStandard;



import authRoleTypes from '../constants/auth/authRoleTypes'
import getRules from '@/rules'
import  currentDomain  from "@/helpers/getDomainName";
const questionRoutesRoleCheck = getRules(currentDomain,'app.generalRules.questionRouteAllowedRoles');
const currentDomainStudentAssignedQuizListPage = getRules(currentDomain,'app.generalRules.studentAssignedQuizListPage') || {
  name:'my-assigned-quizzes',
  path:'/my-assigned-quizzes',
  domain:'myeduquiz.com',
  component:()=>import("@/components/students/my_assigned_quizzes/Index.vue"),
  screen:'student'
};

const routes = [
    {
      name: 'home-page',
      path: '/',
      component: ()=>import('@/components/landing/landing.vue'),
      roles:authRoleTypes.$authorizeFromStudentToSuperAdmin
    },
    {
      name: 'login-page',
      path: '/login',
      component: ()=>import('@/components/login/Index.vue'),
    },

 

    {
      name: 'home-page-student',
      path: '/student',
      component: ()=>import('@/views/student/Index.vue'),
      children:[

        {
          name: 'home-student-stat',
          path: '/home-student-stat',
          component: ()=>import("@/components/students/home_page_student/Index.vue"),
          display: 'Home',
          icon: 'bx-home',
          

         
        },

        {
          name: currentDomainStudentAssignedQuizListPage?.name,
          path: currentDomainStudentAssignedQuizListPage?.path,
          component: currentDomainStudentAssignedQuizListPage?.component,
          display:"My Assignments",
          icon: 'bx-list-ul',
       
        },
      

        {
          name: 'report-student',
          path: '/',
          display: 'Report',
          icon: 'bxs-report',
        
          
          
          children: [

            {
              name: 'my-solved-quizzes',
              path: '/my-solved-quizzes',
              component: ()=>import("@/components/students/my_solved_quizzes/quiz_report_detail/quiz_solved_questions/Index.vue"),
              display: 'Completed',
              icon: 'bxs-check-square',
    
             
            },
    
            {
              name: 'my-expired-quizzes',
              path: '/my-expired-quizzes',
              component: ()=>import("@/components/students/student_expired_quizzes/Index.vue"),
              display: 'Expired',
              icon: 'bx-time-five',
    
             
            },

          ]
        },


        // {
        //   name: 'self-generate-quiz',
        //   path: '/self-generate-quiz',
        //   component: ()=>import("@/components/students/self_quiz_generator/SelfQuizGeneratorIndex.vue"),
        //   display: ' Quiz Generator',
        //   icon: 'bx-layer-plus',
       

         
        // },  

      ],
     

    },
    {
      name: 'student-test-state',
      path: '/student-test-state',
      component: ()=>import("@/components/students/quiz-test-shown/Index.vue"),
      display: 'none',
     
    },
    {
      name: 'lms',
      path: '/lms',
      component: ()=>import('@/views/lms/lms.vue'),
      display: 'Home',
      children: [
    
        {
          name: 'home-page-teacher',
          path: '/lms/home',
          component: ()=>import("../components/home_page/Index.vue"),
          display: 'Home',
          icon: 'bx-home'
        },
  
        {
          name: 'question',
          path: '/lms/question',
          display: 'Question',
          icon: 'bx-question-mark',
          component: ()=>import('@/views/lms/question/question.vue'),
          roles:questionRoutesRoleCheck,
          children: [
            {
              name: 'question-list-page',
              path: '/lms/question/list',
              component: ()=>import('@/components/lms/question/questionList/Index.vue'),
              display: 'List Questions',
              icon: 'bx-list-ul',
              
            },
           
            {
              name: 'question-maker',
              path: '/lms/question/question-maker',
              display: 'Add Question',
              icon: 'bxs-add-to-queue',
              component: ()=>import('@/components/lms/question/Index.vue'),
            
              
              children:[
                {
                  name:"question-general",
                  path:"",
                  component: ()=>import('@/components/lms/question/QuestionGeneral.vue'),
                  props:true,
                  meta:{
                    mode:"question"
                  },
                 
                  
                  
                },

                {
                  name:"question-create-section",
                  path:"question-create-section",
                  component: ()=>import('@/components/lms/question/QuestionCreaterSection.vue'),
                  props:true,
  
                },

                {
                  name:"question-customize-section",
                  path:"question-customize-section",
                  component: ()=>import('@/components/lms/question/customize-question-view/Index.vue'),
                  props:true,
  
                },
              ]

            },


            {
              name: 'list-edit-question',
              path: '/lms/question/edit-listed-question',      
              component: ()=>import('@/components/lms/question/questionList/questionListEditForm.vue'),
              display:'none'
            }
         
        
          ]
        },
   
  
        {
          name: 'quiz',
          path: '/lms/quiz',
          display: 'Quiz',
          icon: 'bx-customize',
          component: ()=>import('@/views/lms/quiz/Quiz.vue'),
          roles:authRoleTypes.$authorizeFromTeacherToSuperAdmin,
          children: [
            {
              name: 'quiz-list-page',
              path: '/lms/quiz/list',
              // component: ()=>import('@/components/lms/quiz/listQuiz/Index.vue'),
              component: ()=>import('@/views/lms/quiz/QuizList.vue'),
              display: 'My Quizzes',
              icon: 'bx-book',
           
              children:[
                {
                  name:"quiz-list",
                  path:"",
                  component: ()=>import('@/components/lms/quiz/listQuiz/Index.vue'),
                  display: 'My Quizzes',
                  icon: 'bx-list-ul',
                  props: true 
                },

              

                {
                  name:"preview-quiz",
                  path:"preview-quiz",
                  component:()=>import('@/components/lms/quiz/listQuiz/quizPreview/Index.vue'),
                  props: true 
                },
                {
                  name:"assign-quiz",
                  path:"assign-quiz",
                  component:()=>import('@/components/lms/quiz/listQuiz/AssignForm.vue'),
                  props: true 
                }
              ]
            },

            {
              name: "quiz-list-all",
              path:"/lms/quiz/list-all-quiz",
              component: ()=>import('@/views/lms/quiz/ListSchoolAllQuiz.vue'),
              display: 'List All Quizzes',
              icon: 'bx-list-ul',
              props: true 
            },
            {
              name: 'quiz-create',
              path: '/lms/quiz/create',
              component: ()=>import('@/views/lms/quiz/QuizCreate.vue'),
              display: 'Create Quiz',
              icon: 'bxs-add-to-queue',
            },

            // {
            //   name: 'assigned-quiz',
            //   path: '/lms/quiz/assigned-quiz',
            //   component: ()=>import('@/views/lms/quiz/AssignedQuiz.vue'),
            //   display: 'Assigned Quiz',
            //   icon: 'bxs-book-content',


            // },

            {
              name: 'edit-quiz',
              path: '/lms/quiz/edit-quiz',
              component: ()=>import('@/components/lms/quiz/edit_quiz/Index.vue'),
             display:'none',
              props:true


            },
            {
              name: 'quiz-report',
              path: '/lms/quiz/quiz-report',
              component: ()=>import('@/views/lms/quiz/QuizReport.vue'),
              display:'none',
              props:true


            },

            {
              name: 'quiz-report-detail',
              path: '/lms/quiz/quiz-report-detail',
              component: ()=>import('@/views/lms/quiz/QuizReportDetail.vue'),
              display:'none',
              props:true


            },
          ]
        },
        {
          name: 'book',
          path: '/lms/book',
          display: 'Books',
          icon: 'bx-book-content',
          component: ()=>import('@/views/lms/book/IndexBook.vue'),
          roles:authRoleTypes.$authorizeToSuperAdmin,
          children: [
            {
              name: 'book-list-page',
              path: '/lms/book/list',
              component: ()=>import('@/components/lms/book/book_list/Index.vue'),
              display: 'List Books',
              icon: 'bx-list-ul',
              
            },
           
            {
              name: 'question-maker-book',
              path: "/lms/question/question-maker-book",
              display: 'Add Book', 
              props:true,
            
              icon: 'bxs-book-add',
              component: ()=>import('@/components/lms/question/Index.vue'),
            
              
              children:[
                {
                  name:"question-book",
                  path:"",
                  component: ()=>import('@/components/lms/question/QuestionGeneral.vue'),
                  meta:{
                    mode:"book"
                  },
                  
                  
                },

                {
                  name:"question-create-section-book",
                  path:"question-create-section-book",
                  component: ()=>import('@/components/lms/question/QuestionCreaterSection.vue'),
                  props:true,
  
                },

             
              ]

            },


            {
              name: 'list-edit-question',
              path: '/lms/question/edit-listed-question',      
              component: ()=>import('@/components/lms/question/questionList/questionListEditForm.vue'),
              display:'none'
            }
         
        
          ]
        },

        {
          name: 'video-quiz',
          path: '/lms/video-quiz',
          display: 'Video Quizzes',
          icon: 'bxs-videos',
          component: ()=>import('@/views/lms/video_quiz/IndexVideoQuiz.vue'),
          roles:authRoleTypes.$authorizeToSuperAdmin,
          children: [
            {
              name: 'video-quiz-list-page',
              path: '/lms/video-quiz/list',
              component: ()=>import('@/components/lms/interactive_video_module/Index.vue'),
              display: 'List Video Quiz',
              icon: 'bx-list-ul',
              
            },
           
            {
              name: 'question-maker-video-quiz',
              path: "/lms/question/question-maker-interactive-video",
              display: 'Add Video Quiz', 
              props:true,
            
              icon: 'bxs-book-add',
              component: ()=>import('@/components/lms/question/Index.vue'),
            
              
              children:[
                {
                  name:"question-interactive-video",
                  path:"",
                  component: ()=>import('@/components/lms/question/QuestionGeneral.vue'),
                  meta:{
                    mode:"video_quiz"
                  },
                  
                  
                },

                {
                  name:"question-create-section-video",
                  path:"question-create-section-video",
                  component: ()=>import('@/components/lms/question/QuestionCreaterSection.vue'),
                  props:true,
  
                },

             
              ]

            },


            {
              name: 'list-edit-question',
              path: '/lms/question/edit-listed-question',      
              component: ()=>import('@/components/lms/question/questionList/questionListEditForm.vue'),
              display:'none'
            }
         
        
          ]
        },
        {
          name: 'analyzes',
          path: '/lms/analyzes',
          display: 'Analysis',
          hideSubRoutes:true,
          icon: 'bx-analyse',
          component: ()=>import('@/views/lms/analyzes/AnalyzesViewIndex.vue'),
          roles:authRoleTypes.$authorizeFromCampusAdminToSuperAdmin,
          children:[
            {
              name: 'analyzes-campus-report',
              path: '/lms/analyzes/report-campus',
              component: ()=>import('@/components/lms/analyzes/analyzes_section/CampusAnalyzes.vue'),
              display: 'none',
            },
            {
              name: 'analyzes-class-report',
              path: '/lms/analyzes/report-class',
              component: ()=>import('@/components/lms/analyzes/analyzes_section/ClassAnalyzes.vue'),
              display: 'none',
            },
            {
              name: 'analyzes-teacher-report',
              path: '/lms/analyzes/report-teacher',
              component: ()=>import('@/components/lms/analyzes/analyzes_section/TeacherAnalyzes.vue'),
              display: 'none',
            },
            {
              name: 'analyzes-student-report',
              path: '/lms/analyzes/report-student',
              component: ()=>import('@/components/lms/analyzes/analyzes_section/StudentAnalyzes.vue'),
              display: 'none',
            }

          ]
        },

        {
          name: 'user-management',
          path: '/lms/user-management',
          display: 'Users',
          icon: 'bxs-user-badge',
          component: ()=>import('@/views/lms/user_list/IndexUserList.vue'),
          roles:authRoleTypes.$authorizeToSuperAdmin,
          hideSubRoutes:true,
          children:[]
      
        },
        {
          name: 'report',
          path: '/lms/report',
          display: 'Report',
          icon: 'bxs-report',
          component: ()=>import('@/views/lms/Report/ReportIndex.vue'),
          roles:authRoleTypes.$authorizeFromTeacherToSuperAdmin,
          children: [

            {
              name: 'assigned-quiz',
              path: '/lms/quiz/assigned-quiz',
              component: ()=>import('@/views/lms/quiz/AssignedQuiz.vue'),
              display: 'Assigned Quizzes',
              icon: 'bxs-compass',

            },
            {
              name: 'outcomes-details',
              path: '/lms/outcome-details',
              component: ()=>import('@/views/lms/Report/IndexOutcomeDetail.vue'),
              display: 'Outcomes',
              icon: 'bxs-compass',
             


            },

          ]
        }
   
      ]
    },
  ];

export default routes;


<template>
  <div class="response-box-container"  v-if="selectedMessageComponent">
    <component :is="selectedMessageComponent" :timer="responseStatusBox?.timer || 0" :responseStatusMessages="responseStatusBox?.responseStatusMessages"></component>
  </div>
</template>

<script>
import NotFoundBox from './NotFoundBox.vue';
import FilterNotFull from './FilterNotFull.vue';
import NonExistItem from './NonExistItem.vue';
export default {
    props:{ 
        responseStatusBox: {
            type:Object,
            default:null
        } 
    },
    components:{
        NotFoundBox,
        FilterNotFull,
        NonExistItem 
    },
    computed:{
        selectedMessageComponent(){
            if(this.responseStatusBox?.messageType === 'not_found'){
                return 'NotFoundBox'
          }
          else if(this.responseStatusBox?.messageType === 'filter_not_full'){
            return 'FilterNotFull'
          }
          else if(this.responseStatusBox?.messageType === 'non_exist_item'){
            return 'NonExistItem'
            
          }
else if(this.responseStatusBox?.messageType === 'success'){
return 'SuccessBox'
}
else if(this.responseStatusBox?.messageType === 'error'){
return 'ErrorBox'
}
            return '';
        }
    },

 
}
</script>

<style scoped lang="scss">
.response-box-container {
  max-width: 50vw;
  min-width: 28vw;
  width: max-content;
  height: 12vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: #ffffffea;
  position: relative;
  margin: auto;
  border-radius: 0;
  scale: .85;


}
</style>


  import  PInput from "primevue/inputtext";
  import PTextarea from "primevue/textarea";
  import PDropdown from "primevue/dropdown";
  import PButton from "primevue/button";
  import PToggle from "primevue/togglebutton";
  import PTable from "primevue/datatable";
  import PColumn from 'primevue/column';
import PColumnGroup from 'primevue/columngroup';     //optional for column grouping
import PRow from 'primevue/row'; 
  import PPagination from "primevue/paginator";
  import PSelect from "primevue/selectbutton";
  import PTreeSelect from 'primevue/treeselect';
  
 export const  settings = {
    'p-input': {
      component: PInput,
      props: {
        classes: 'border-gray-300 block w-full rounded text-gray-800 placeholder-gray-400'
        // ...More settings
      }
    },
    'p-textarea': {
      component: PTextarea,
      props: {
        classes: 'border-2 block w-full rounded text-gray-800'
        // ...More settings
      }
    },
    'p-button': {
      component: PButton,
     
    },
    'p-dropdown': {
      component: PDropdown,
      props: {
        // classes: 'focus:outline-none',
        // ...More settings
      }
    },
    'p-toggle': {
      component: PToggle,
      props: {
        // classes: 'focus:outline-none',
        // ...More settings
      }
    },
    'p-tree-select': {
        component: PTreeSelect,
        props: {
          // classes: 'focus:outline-none',
          // ...More settings
        }
      },
    'p-table': {
      component: PTable,

    },
    'p-column': {
        component: PColumn,
       
      },
      'p-column-group': {
        component: PColumnGroup,
       
      },

      'p-row': {
        component: PRow,
       
      },
      
    'p-pagination': {
      component: PPagination,
      props: {
        classes: {
          wrapper: 'table border-collapse text-center bg-white mx-auto shadow-sm',
          element: 'w-8 h-8 border border-gray-200 table-cell hover:border-blue-100',
          activeElement: 'w-8 h-8 border border-blue-500 table-cell hover:border-blue-600',
          disabledElement: 'w-8 h-8 border border-gray-200 table-cell',
          ellipsisElement: 'w-8 h-8 border border-gray-200 hidden md:table-cell',
          activeButton: 'bg-blue-500 w-full h-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
          disabledButton: 'opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out',
          button: 'hover:bg-blue-100 w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
          ellipsis: '',
        },
        // ...More settings
      }
    },
    'p-select': {
      component: PSelect,
 
    },
    // ...Rest of the components
  }



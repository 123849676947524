import authRoleTypes from '@/constants/auth/authRoleTypes';
import seoSettingRules from './seoSettingsRules';
export default {
    appName : 'MyEduConcept',
    appIcon: 'icons/myeduconcept-logo.png',
    loginPageIcon: 'icons/myeduconcept-logo.png',
    loginPageDesign:{
        formBgColor:`background: #403A3E;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #BE5869, #403A3E);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to top, #BE5869, #403A3E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `,
        loginPageBackgroundColor:`background: rgb(127,137,177);
        background: linear-gradient(to bottom, rgba(127,137,177,1) 0%, rgba(175,184,224,1) 50%, rgba(190,205,208,1) 100%);
        `
       

    },
    generalRules:{
        isDefaultScoolVisible:true, // Teknopark by default visible or not check.
        questionRouteAllowedRoles:authRoleTypes.$authorizeToSuperAdmin,
        studentAssignedQuizListPage: {
            name:'myeduconcept-assigned-quizzes',
            path:'/myeduconcept-assigned-quizzes',
            domain:'myeduconcept.com',
            component:()=>import("@/components/students/myeduconcept/concept_assigned_quizzes/IndexConceptAssignmentsShow.vue"),
            screen:'student'
        }

    },

    seoSettings:seoSettingRules()
  
}
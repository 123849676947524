import axios from 'axios';
import tools from '@/tools';
import store from '@/store/index';
import router from '@/router/index';
import sendErrorToSlack from "../constants/app/slackErrorhandler"

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const token = tools.cookie.get('authToken');
  // eslint-disable-next-line no-param-reassign



  config.baseURL = "https://api.myeduquiz.com/api/v0.0.1/"
 
  // config.baseURL = 'https://uesquizmaker-api.ey.r.appspot.com/api/v0.0.1/';
  // config.baseURL = 'http://localhost:4000/api/v0.0.1/';
  // config.baseURL = "https://test-dot-uesquizmaker-api.ey.r.appspot.com/api/v0.0.1/"

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(res => res.data, async (err) => {
  const sc = err?.response?.status || 0;
  const messageErr = err?.response?.data?.message || '';
  // const userRole = store.state.user.role;
      // Hata durumunda HTTP durum kodunu al
      const requestedURL = err.config.url;
      const statusCode = err.response ? err.response.status : 'Network Error';
      const statusText = err.response ? err.response.statusText : 'Network Error';
      const errorMessage = `HTTP Error: ${requestedURL} & ${messageErr} - Status ${statusCode} ${statusText}`;
  
      // Hata mesajını Slack'e gönder
      sendErrorToSlack(errorMessage);

  if (sc === 401) {

    store.dispatch("user/logout").then((res) => {
      if (res)  window.location.reload();
    });
  }
  throw err;
});

export default () => instance;
<template>
  <div id="app">
    <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `SITE_NAME` }}</template>
  </metainfo>
    <!-- <error-boundary>
    </error-boundary> -->
    <router-view></router-view>


    <loading v-if="loadingBar"></loading>
  </div>
</template>


<script>
import tools from "@/tools";
import { mapGetters } from "vuex";
import _ from "lodash";
import CryptoJS from "crypto-js";
import activeAppDomains from "@/constants/app/activeAppDomains";
import ErrorBoundary from "./components/utilComponents/ErrorBoundary.vue";
import getRules from './rules';
import { useMeta } from 'vue-meta'


export default {
  name: "App",

  components: {
    ErrorBoundary
  
  },

  data() {
    return {
      logoutChannel: null,
    };
  },

  computed: {
    ...mapGetters({ modalStatus: "getModalStatus" }),
    loadingBar() {
      return this.$store.getters.getResponseFetchedStatus;
    },
  },

  mounted() {

    this.updateUserRecordInteraction();

    window.onbeforeunload = this.resetLoadingBar;


    if ('BroadcastChannel' in window) {

    // Yeni bir BroadcastChannel oluştur
    this.logoutChannel = new BroadcastChannel("logout");

    const {role = null} = JSON.parse(sessionStorage.getItem("userData") )  || {};
    
      this.logoutChannel.onmessage = (event) => {

        const currentRoute = this.$router.name;

      if(role !== 'super-admin' && role && currentRoute !== 'student-test-state'){

        this.$store.dispatch("user/logout").then((res) => {
        if (res) window.location.reload();
      });
      }

};
    
  }
 
  },

  watch: {
    modalStatus(newValue) {
      let app = document.getElementById("app");
      if (newValue) {
        let modalDiv = document.createElement("div");
        modalDiv.id = "modal-bg";
        modalDiv.style = "z-index:0;";
        modalDiv.className =
          "w-full h-full fixed block top-0 left-0 bg-black bg-opacity-75  ";
        app.append(modalDiv);
      } else {
        let removedElement = document.getElementById("modal-bg");
        if (removedElement?.parentNode) {
          removedElement.parentNode.removeChild(removedElement);
        }
      }
    },
  },

  
  methods: {
    updateUserRecordInteraction() {
      const userData = JSON.parse(sessionStorage.getItem("userData")) || {};
      const adminRole = "super-admin";
      if (userData?.role && !(userData?.role === adminRole)) {
        this.$store.commit("user/recordUserSystemInteractionTime");
      }
    },

    resetLoadingBar() {
        // make loading spin visible set as true
        this.$store.commit("setResponseFetched", false);
    }
  },

  async created() {

    let seoSettings = {};

    let domainName =   window?.location?.hostname || "localhost";
    const activeDomains = activeAppDomains?.domains || [];
    const defaultDomain = activeAppDomains?.defaultDomain || "";
    if (!activeDomains.includes(domainName)) {
      this.$store.commit("setDomainName", defaultDomain);
      seoSettings = getRules(defaultDomain,'app.seoSettings')
    } else {
      if (!domainName.includes("www.")) {
        domainName = "www." + domainName;
      }

      this.$store.commit("setDomainName", domainName);
      seoSettings = getRules(domainName,'app.seoSettings')
    }

    useMeta(seoSettings)

    let userInfoSSO = {};
    const params = new URL(window.location.href)?.searchParams;
    const arr = params.get("sso");

    if (_.isEmpty(arr) === false) {
      try {
        const decode = decodeURIComponent(atob(arr));

        const secret = process.env.VUE_APP_SSO_SECRET_KEY || null;

        const bytes = CryptoJS.AES.decrypt(decode, secret).toString(
          CryptoJS?.enc?.Utf8
        );

        userInfoSSO = JSON.parse(bytes);

      } catch (error) {
        console.log(error)
      }
   
    }

    if (_.isEmpty(userInfoSSO) === false) {
      if (
        this.$authorizeFromTeacherToSuperAdmin.includes(
          userInfoSSO.role_type.toLowerCase()
        ) &&
        !userInfoSSO?.password
      ) {
        const teacherDefaultPassword =
          process?.env?.VUE_APP_DEFAULT_SSO_TEACHER_PASSWORD || "";
        userInfoSSO["password"] = teacherDefaultPassword;
      } else if (!userInfoSSO?.password) {
        const studentDefaultPassword =
          process?.env?.VUE_APP_DEFAULT_SSO_STUDENT_PASSWORD || "";

        userInfoSSO["password"] = studentDefaultPassword;
        userInfoSSO["role_type"] = "student";
      }

      sessionStorage.setItem("ssoUserInfo", JSON.stringify(userInfoSSO));
      window.location.href = "/#/login";
    }
  },

  beforeMount() {

    const isUserDataExist = sessionStorage.getItem("userData");

    if (!tools.cookie.get("authToken") || !isUserDataExist) {
      this.$router.push("/login");
    }
  },

  beforeUnmount() {
    // Kanalı kapat
    if (this.$store.getters["user/getLoggedOut"]) {
      this.logoutChannel.close();
    }
  
  },


};
</script>


<style lang="scss">
@import "@/assets/scss/app.scss";
@import "@/assets/scss/scroll.scss";

* {
  font-family: "Open Sans";
  font-style: normal;
}
.tooltip {
  display: block !important;
  z-index: 10000;
  font-size: 16px;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

/* Bu CSS kodu, sayfa denetim aracı açıldığında beyaz bir kaplama oluşturacak ve sayfanın içeriğini örtecektir.
 */
div#overlay {
  display: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  div#overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    background-color: white;
    opacity: 0.7;
  }
}
</style>

const getMyStandard = (standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef) => {

    const _this = compenentRef;

    const cefrStandard = {
     resources: [{
         key: 'GND Einstufungstest',
         label: 'GND Einstufungstest',
       }]
                         }

 
 
     _this[standardRefOptions] = cefrStandard[standardRefOptions];
     _this[standardName] = _this[standardRefOptions][0]?.key;
     _this[triggerUpdateFunct](_this[standardRefOptions][0]?.key, 'resource');

   

}

export default getMyStandard;
function getQuestionStandarts({role = 'super-admin'}) {

  return [
    {
      key: "cefr",
      label: "CEFR",
    },
  
   
  
  ]
}

export default {
  questionStandarts:getQuestionStandarts
}
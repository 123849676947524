<template>

<KeepAlive>
    <n-input
    v-model:value="currentValue"
    :autofocus="true"
    type="text"
    placeholder="search"
    :loading="isLoading"
    @keypress.enter.prevent
    style="font-size: .8vw !important;"
    >

  <template #suffix>
        <n-icon :component="SearchOutline" />
      </template>
      
    </n-input>
</KeepAlive>
</template>

<script>
import { mapGetters } from 'vuex'
import { SearchOutline } from '@vicons/ionicons5'
import {debounce} from 'lodash'

export default {
 
    props:{
        optionsData:{
            required:true,
            default:()=>{}
        }
    },
data(){
    return {
        currentValue:'',
        isLoading:false,
        SearchOutline
    }
},
computed:{
    ...mapGetters({paginateIndex:'getPaginationIndex'}),
},
methods:{
    debouncedEmit: debounce(async function(newValue) {

        this.$store.commit('setPaginationIndex',{currentIndex:1,currentDataGroupCount:0})
        if(newValue != ''){
            this.isLoading = true;
            await this.$store.dispatch(this.optionsData.actionName,{...this.optionsData.config, value:newValue,paginateIndex:this.paginateIndex})
            this.isLoading = false;
            this.$emit('searchBoxActive',newValue)

        }
        else {
            this.isLoading = true;
            
            let updatedConfig = {... this.optionsData.config};
            
            updatedConfig.paginateIndex = 1;

            await this.$store.dispatch(this.optionsData.defaultActionName,updatedConfig)
            this.isLoading = false;

            this.$emit('searchBoxActive','')

        }
    }, 500), // 500 milisaniye gecikme
},

watch:{
   
    async currentValue(newValue){
        this.debouncedEmit(newValue)
    }
},




}
</script>



import gundogduStandard from './gundogdu_school_demo_standard/index';
import lgsxxlargeStandard from './lgsxxlarge_demo_standard/index';

// LGSXXLARGE school id. If the school is LGSXXLARGE will only show lgs 2 quiz types. 
const lgxxlargeID = process?.env?.VUE_APP_LGSXXLARGE_ID;

 // Gundogdu school id.
 let gundogduId = process?.env?.VUE_APP_GUNDOGDU_ID ;

const getMyStandard = (standardType,standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef) => {
  if (lgxxlargeID === compenentRef[userData]?.institutionId){
    lgsxxlargeStandard(standardType,standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef);
  }

 if(gundogduId === compenentRef[userData]?.institutionId) {
  gundogduStandard(standardType,standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef);

   }

};
export default getMyStandard ;
    


const domainsByPlatforms = {
    'www.myeduquiz.com':'my-edu-quiz',
    'myeduquiz.com':'my-edu-quiz',
    'www.gr8vault.com':'my-edu-quiz',
    'gr8vault.com':'my-edu-quiz',
    'lgsxxlarge.com':'LGSXXLARGE',
    'www.lgsxxlarge.com':'LGSXXLARGE',
    'myeduconcept.com':'my-edu-concept',
    'www.myeduconcept.com':'my-edu-concept'
}
export default domainsByPlatforms
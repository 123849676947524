const domain = 'https://www.myeduconcept.com';

export default {

                meta: [
                  {
                    name: 'description',
                    content: `Revolutionize your learning experience with our platform focused on CONCEPT exams for students! This platform is designed specifically for students and teachers, allowing the creation of exams, interactive books, and video content. Students can solve assigned exams, create their own using our platform, and access detailed analysis reports. Teachers can craft various questions, assemble exams, and monitor student progress. Elevate education with our comprehensive learning management system, fostering an engaging and analytical learning environment!`
                  },
                  {
                    rel: 'icon',
                    href: domain + '/favicon_concept.ico'
                  },
                  {
                    type: 'application/ld+json',
                    json: {
                      "@context": "http://schema.org",
                      "@type": "EducationalOrganization",
                      "name": "Myeduconcept",
                      "url": domain,
                      // Diğer ilgili alanlar
                    }
                  },
                  {
                    name: 'robots',
                    content: 'index, follow'
                  },
                  {
                    name: 'keywords',
                    content: 'AI-powered quiz maker,Concept, interactive learning platform, online quiz creator, education technology tools, e-learning solutions, student assessment software, customizable quizzes, teacher resources, automated quiz generation, educational analytics, LMS software, remote learning tools, educational gamification, interactive video content, online learning management system'
                },
                  // Open Graph Etiketleri
                  {
                    property: 'og:title',
                    content: 'Myeduconcept Interactive Quiz Maker - Platform for Students and Teachers focusing on CONCEPT contents.'
                  },
              {
                property: 'og:description',
                content: 'Transform your learning experience with our AI-powered Quiz Maker! Create interactive quizzes, books, and video content for both students and teachers. Discover education through personalized learning.'
              },
              {
                property: 'og:type',
                content: 'website'
              },
              
              {
                property: 'og:url',
                content: domain
              },
              {
                property: 'og:image',
                content: domain +'/icons/myeduconcept-logo.png'
              },
            
              // Ekstra Etiketler
              {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1'
              },
              {
                name: 'author',
                content: 'UES - Unlimited Education Services'
              },
              {
                name: 'language',
                content: 'English'
              },
            ],
            link: [
              {
                rel: 'canonical',
                href: domain
              }
            ]
        
        }
      
      

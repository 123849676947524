import questionDemoSubStandard from './question_standard/index';
import getMyHeadStandart from './demo_question_head_standard/index';

const questionStandardHeadRef = "questionStandardHead";


const getMyStandard = (standardType,standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef) => {
    if(questionStandardHeadRef === standardType){
        getMyHeadStandart(standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef);
    }
    else {
        questionDemoSubStandard(standardType,standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef);

    }
}

export default getMyStandard;
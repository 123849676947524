import axios from '@/services';
import getRules from '@/rules'
import  currentDomain  from "@/helpers/getDomainName";
import getSpesificStandardByUser from "@/constants/standards/indexStandards"

const defaultState = {
    quizMixedQuestionAllowed: false,
        quizResourcesContainer: {},
        assignedQuizAllTargets: {},
        quizBanner: null,
        assignedQuizCount:0,
        assignedQuizzes: [],
        selectedAssignedQuizToEdit: null,
        quizPreviewQuestions: [],
        teacherQuizzes: [],
        quizDetailFilterItems: [],
        listQuizzesFilter: {
            selectedQuizType: '',
            createdDate: '',
            strictedSearchActive: false

        },
        selectedQuizToAssign: {},
        editQuizInfo: null,
        quizCount: 0,
        quizReportDetailIds: [],
        quizSolvedIds: [],
        selectedQuizCreateQuestions: [],
        reportOutcomeData: {},
        selectedSelfQuizReportDetail:{},
        selectedAssignedQuizDetail: [],
        totalBookCount:0,
        totalVideoQuizCount:0
}
const questionDefaultStandards = getRules(currentDomain,'question.questionStandartRules.questionStandarts').
map(standard => standard.key);


export default {

    namespaced: true,
    state: defaultState,

    mutations: {

        setAssignedQuizCount(state,count){
            state.assignedQuizCount = count;
        },
   

        setSelfQuizReportState(state, quizDetail) {
            state.selectedSelfQuizReportDetail = quizDetail;
           
        },
        resetQuizState(state){
            state = defaultState;
      
          },
        setTotalBookCount(state,count){
            state.totalBookCount = count ;
        },
        setTotalVideoQuizCount(state,count){
            state.totalVideoQuizCount = count ;
        },
        setAssignedQuizAllTargets(state, targets) {
            state.assignedQuizAllTargets = targets;
        },
        setFilterItems(state, filterItems) {
            state.quizDetailFilterItems = filterItems;
        },
        async setMyQuizzes(state, quizzes) {

            if (quizzes.length) {
                let setAssignedQuizEditStatus = async function (id, isAssigned) {

                    if (isAssigned) {
                        try {

                            let { status_code, isQuizEditable } = await axios().post('quiz/is-assigned-quiz-start', { id });
                            if (status_code === 200) {

                                return isQuizEditable
                            }

                        } catch (error) {
                            alert(error);
                            return false
                        }

                    }
                    else {
                        return true
                    }
                };
                state.teacherQuizzes = await Promise.all(quizzes.map(async itm => {

                    itm.isQuizEditAble = await setAssignedQuizEditStatus(itm._id, itm?.isQuizzAssigned)

                    return itm
                })

                )


            }
            else {
                state.teacherQuizzes = []
            }

        },

        setListQuizzesFilter(state, filter) {
            state.listQuizzesFilter = filter
        },
        setQuizCount(state, quizCount) {
            state.quizCount = quizCount;
        },


        quizPreviewSetQuestions(state, questions) {

            state.quizPreviewQuestions = questions
        },

        setSelectedQuizToAssign(state, quiz) {
            state.selectedQuizToAssign = quiz
        },
        setAssignedQuizzes(state, quizzes) {

            state.assignedQuizzes = quizzes;

        },
        setAssignedQuizOutcomeTargets(state, targets) {
            state.assignedQuizOutcomeTargets = targets
        },
        setSelectedAssignedQuizToEdit(state, assignedQuiz) {
            state.selectedAssignedQuizToEdit = assignedQuiz;
        },
        setQuizReport(state, quizDetail) {

            state.selectedAssignedQuizDetail = quizDetail;


        },

        setSelectedReportOutcomeData(state, outcomeData) {
            state.reportOutcomeData = outcomeData;
        },


        resetSelectedReportOutcomeData(state) {
            state.reportOutcomeData = {};
        },

        setQuizReportDetailIds(state, ids) {
            state.quizReportDetailIds = ids;
        },
        setEditQuizInfo(state, quizInfo) {
            state.editQuizInfo = quizInfo;
        },

        setEditQuizInfoByPropert(state, { propertyName, data }) {
            if(Object.keys(state?.editQuizInfo || {}).includes(propertyName) ){
                state.editQuizInfo[propertyName] = data;

            }
        },
        setSelectedQuestion(state, question) {
            if (question == '') {
                state.selectedQuestions = [];
            }
            state.selectedQuestions.push(question);
        },
        setQuizQuestions(state, questions) {
            state.selectedQuestions = questions;



        },

        updateQuiz(state, quizDeleted) {
            state.teacherQuizzes = state.teacherQuizzes?.filter(quiz => {
                return quiz.id != quizDeleted.id
            })
        },

        setQuizMixedQuestionAllowed(state, allowed) {
            if (!allowed) {
                state.selectedQuizCreateQuestions = [];
                this.commit('question/resetFilteredQuestions');

            }
            state.quizMixedQuestionAllowed = allowed
        },



        setQuestionPool(state, questions) {

            state.questionsPool = questions;
        },
        removeQuestionList(state, item) {
            state.selectedQuestions = state.selectedQuestions.filter(x => x.Soru_Id !== item.Soru_Id);
        },

        setQuizSolvedIds(state, ids) {
            state.quizSolvedIds = ids
        },

        setSelectedQuestionQuizCreate(state, { type, question }) {

            if (type === 'single') {
                state.selectedQuizCreateQuestions.push(question);
            }
            else if (type === 'multi') {
                state.selectedQuizCreateQuestions = question

            }
            else if (type === 'delete') {
                state.selectedQuizCreateQuestions = state.selectedQuizCreateQuestions.filter(ques => {
                    return ques._id != question._id
                })
            }
            else {
                state.selectedQuizCreateQuestions = []
            }

        },

        setQuizResourcesContainer(state, { propertyName, data = null }) {


            if (data == 'reset') {
                state.quizResourcesContainer = {};

            }
            else if (propertyName === 'all-save') {
                state.quizResourcesContainer = data;
            }

            else if (data || data !== null) {
                state.quizResourcesContainer[propertyName] = data;
            }

            else {

                delete state.quizResourcesContainer[propertyName];

            }


        },

        setQuizBannerImage(state, quizBanner) {
            state.quizBanner = quizBanner
        },


    },

    actions: {
        async getBooks({commit},filter){
            try {
                const { status_code, bookQuizzes, quizBookTotalCount = 0 } = await axios().post('quiz/get-book-quizzes',filter);
                if(status_code === 200){

                    commit('setTotalBookCount',quizBookTotalCount);
                    return bookQuizzes;
                }
                else return [];  
            } catch (error) {
                console.log(error);
                return [];
            }
         
        },
        async getVideoQuizzes({commit},filter){
            try {
                const { status_code, videoQuizzes, quizVideoTotalCount = 0 } = await axios().post('quiz/get-video-quizzes',filter);
                if(status_code === 200){

                    commit('setTotalVideoQuizCount',quizVideoTotalCount);
                    return videoQuizzes;
                }
                else return [];  
            } catch (error) {
                console.log(error);
                return [];
            }
         
        },

        
        // async keywordSearchGetQuizzes({ commit }, { institutionIds, institutionSubSchoolIds, role, uesIds, value, filter, paginateIndex}) {
         
        //     const currentUserId = JSON.parse(sessionStorage.getItem("userData") || '{}')?.uesId || '';
        //     const onlyConceptStandardShowUser = process.env.VUE_APP_ONLY_CONCEPT_STANDARD_SHOW_USER || ''; 
        //     const isCefrConceptType = currentUserId == onlyConceptStandardShowUser;
        //     const defaultStandards = role === 'super-admin' ? [
        //         'lgs2','cefr','lgs','kw3'
        //     ] : (isCefrConceptType ? ['cefr'] : questionDefaultStandards);

        //     let {
        //         status_code,
        //         data: { quizzes, quizzesTotalCount },
        //     } = await axios().post('quiz/keywordFilterQuizzes', {
        //         institutionIds,
        //         institutionSubSchoolIds,
        //         role,
        //         uesIds,
        //         value,
        //         filterQuizzes: filter,
        //         paginateIndex,
        //         defaultStandards,
        //         isCefrConceptType 

        //     });




        //     if (status_code == 200) {
        //         await commit("setMyQuizzes", quizzes);
        //         commit("setQuizCount", quizzesTotalCount);
        //         return true;
        //     }
        //     else {
        //         return false;
        //     }
        // },


        async getMyQuizReport({ commit, getters }, quizValue) {

            const filterItem = getters["getQuizDetailfilterItems"] || [];
            quizValue["filterItems"] = filterItem;


            let { status_code, data: { quizSolved, quizzesTotalCount } } = await axios().post('quiz/get-solved-quizzes', quizValue);

            // commit("setQuizSolvedIds", quizSolvedIds);
       
            commit("setQuizCount", quizzesTotalCount);

            if (status_code == 200) commit('setQuizReport', quizSolved);

            return status_code

        },
        async getMyUnsolvedQuizReport({ commit, getters }, quizValue) {

            const filterItem = getters["getQuizDetailfilterItems"] || [];
            quizValue["filterItems"] = filterItem;


            let { status_code, data: { quizNotSolvedStudentList, totalQuizNotSolvedStudent } } = await axios().post('quiz/get-not-solved-quizzes', quizValue);

            // commit("setQuizSolvedIds", quizSolvedIds);
            commit("setQuizCount", totalQuizNotSolvedStudent);

            if (status_code == 200) commit('setQuizReport', quizNotSolvedStudentList);

            return status_code

        },

        createQuiz({ commit }, quiz) {

            return axios().post('/quiz/create-quiz', quiz).then(({ status_code }) => {
                if (status_code == 201) return true;
                return false;


            }).catch(err => {
                return err
            })

        },

        async getTeacherQuizzes({ commit }, { apiPath, institutionIds, institutionSubSchoolIds, role, uesIds, filter, paginateIndex }) {
            
            const currentUserId = JSON.parse(sessionStorage.getItem("userData") || '{}')?.uesId || '';
            const userData = JSON.parse(sessionStorage.getItem("userData"));
            const institutionId = userData?.institutionId || '';
            const campusId = userData?.institutionSubSchoolId || '';
            const onlyConceptStandardShowUser = process.env.VUE_APP_ONLY_CONCEPT_STANDARD_SHOW_USER || ''; 
            const isCefrConceptType = currentUserId == onlyConceptStandardShowUser;
            let subLimitedStandards = {};
            const specificStandardsByUser = getSpesificStandardByUser(institutionId, campusId);


            const defaultStandards = role === 'super-admin' ? [
                'lgs2','cefr','lgs','kw3'
            ] : (isCefrConceptType ? ['cefr'] : questionDefaultStandards);
            
            
            if (specificStandardsByUser.data.length) {
                subLimitedStandards = specificStandardsByUser.filter;
            }
            let {
                status_code,
                data: { quizzes, quizzesTotalCount },
            } = await axios().post(apiPath, {
                institutionIds,
                institutionSubSchoolIds,
                role,
                uesIds,
                filterQuizzes: filter,
                paginateIndex,
                defaultStandards,
                isCefrConceptType,
                subLimitedStandards
            });


           

            if (status_code == 200) {

                await commit("setMyQuizzes", quizzes);
                commit("setQuizCount", quizzesTotalCount);
                return true;
            }
            else {
                return false;
            }
        },


        async deleteQuiz({ commit }, _id) {

            const { status_code } = await axios().delete('/quiz/delete-quiz', { data: { _id } });
            if (status_code == 200) commit('updateQuiz', _id);
            return status_code;


        },

        async deleteAssignedQuiz({ commit }, _ids) {
            let assignedQuizIds = Array.isArray(_ids) ? _ids : [_ids];
            const { success } = await axios().delete('/quiz/delete-assigned-quizzes', { data: { assignedQuizIds } });

            return success;


        },

        async editAssignedQuiz({ commit }, _id) {

            const { status_code } = await axios().delete('/quiz/delete-quiz', { data: { _id } });
            if (status_code == 200) commit('updateQuiz', _id);
            return status_code;


        },


        quizPreviewSetQuestions({ commit }, questions) {


            return axios().post('/question/get-questions-byId', questions).then(({ status_code, selectedQuestions }) => {
                if (status_code == 200) {

                    commit('quizPreviewSetQuestions', selectedQuestions)
                    return status_code
                }

            }).catch(err => {
                return err
            })
        },



        async quizUpdate({ commit }, quizInfo) {
            try {
                let { status_code } = await axios().patch('quiz/quiz-update', quizInfo);
                if (status_code == 200) return true;
                return false;

            } catch (error) {
                console.log(error)
                return false;
            }

        },

        async getQuizAssignedList({ commit }, quizInfo) {


            try {

                let { data: { assignedQuiz, quizzesTotalCount }, status_code } = await axios().post('quiz/get-assigned-quizzes', quizInfo);
                await axios().post('quiz/get-teacher-assigned-quiz-targets', quizInfo).then(({ status_code, assignedAllTargetList = [] }) => {
                    if (status_code == 200) {
                        commit('setAssignedQuizAllTargets', assignedAllTargetList);
                    }
                  
                }).catch(err => {
                    console.log(err)
                })
                commit("setAssignedQuizzes", assignedQuiz);
                commit("setAssignedQuizCount", quizzesTotalCount);
            

                if (status_code == 200) return { status: true };
                return { status: false };
                
            }
            catch (error) {
                console.log(error)
                return { status: false };

            }
         

        },

        async assignQuiz({ dispatch }, quiz) {


            const res = await axios().post("/quiz/assign-quiz", quiz)

            return res;
        },



        async getAssignedQuizOutcomeTargets({ commit }, targetsData) {
            try {
                let res = await axios().post('quiz/get-report-outcome-targets', targetsData);
                if (res.status == 200) {

                    return { status: true, result: res?.result };
                }
                return { status: false, result: false };
            } catch (error) {
                alert('Outcome target could not found !');
                return { status: false, result: false };
            }


        },

        async getOutcomeReportData(context, { type, filterData, assignedQuizId }) {
            try {


                let res = await axios().post(`quiz/get-outcome-result-${type}`, { filterData, assignedQuizId });

                if (res.status == 200 && Object.keys(res?.result).length) {

                    const result = Object.values(res.result);
                    let outcomeModified = {
                        data: Object.entries(res.result).map(([key, value], index) => {
                            return {
                                outcome: key,
                                index,
                                correctCount: value.correctCount,
                                incorrectCount: value.incorrectCount
                            }
                        }),
                        totalCorrectCount: result.reduce((acc, rst) => {
                            return acc + rst.correctCount
                        }, 0),
                        totalIncorrectCount: result.reduce((acc, rst) => {
                            return acc + rst.incorrectCount
                        }, 0),
                    }
                    return { status: true, result: outcomeModified }
                }

                else {

                    return { status: false, result: false, solvedQuizzesCount: res?.solvedQuizzesCount };
                }


            } catch (error) {
                alert(error);
                return { status: false, result: false, solvedQuizzesCount: 0 };
            }
        }
    },

    getters: {

        getSelfGeneratedSelectedQuizDetail(state) {
            return state.selectedSelfQuizReportDetail
        },
        getAssignedQuizCount(state) {
            return state.assignedQuizCount
        },
        getTotalBookCount(state){
            return state.totalBookCount
        },
        getTotalVideoQuizCount(state){
            return state.totalVideoQuizCount
        },

        getAssignedQuizAllTargets(state) {
            return state.assignedQuizAllTargets
        },
        getAssignedQuizzes(state) {
            return state.assignedQuizzes
        },
        getSelectedAssignedQuizToEdit(state) {
            return state.selectedAssignedQuizToEdit;
        },
        // getEditQuizInfo(state) {
        //     return state.editQuizInfo;
        // },
        getMyQuizzes(state) {
            return state.teacherQuizzes
        },
        getListQuizzesFilter(state) {
            return state.listQuizzesFilter;
        },
        getQuizPreviewQuestions(state) {
            return state.quizPreviewQuestions;
        },
        getSelectedQuiz(state) {
            return state.selectedQuizToAssign;
        },
        getAllConstData(state) {
            return state.allConstData;
        },
        getQuizDetailInfo(state) {
            return state.editQuizInfo
        },
        getQuizCount(state) {
            return state.quizCount;
        },

        getQuizReports(state) {
            return state.selectedAssignedQuizDetail;
        },
        getSelectedReportOutcomeInfo(state) {
            return state.reportOutcomeData
        },


        getQuizReportDetailIds(state) {
            return state.quizReportDetailIds
        },

        getQuizSolvedIds(state) {
            return state.quizSolvedIds
        },

        getSelectedQuizCreateQuestions(state) {
            return state.selectedQuizCreateQuestions;
        },
        getQuizResourcesContainer(state) {
            return state.quizResourcesContainer;
        },
        getQuizBanner(state) {
            return state.quizBanner;
        },
        getQuizMixedQuestionAllowed(state) {
            return state.quizMixedQuestionAllowed
        },
        getQuizDetailfilterItems(state) {
            return state.quizDetailFilterItems;
        }




    }
}
const domain = 'https://www.lgsxxlarge.com';

export default {

                meta: [
              {
                name: 'description',
                content: `Öğrencilere özel hazırlanmış, LGS odaklı sınavları sunan platformumuzla öğrenme deneyiminizi devrimleştirin! Bu platform, öğrencilere ve öğretmenlere özel olarak tasarlanmış olup, sınavlar, etkileşimli kitaplar ve video içerikler oluşturmanıza olanak tanır. Öğrenciler, atanan sınavları çözebilir, kendi sınavlarını oluşturabilirler ve ayrıntılı analiz raporlarına erişebilirler. Öğretmenler ise çeşitli sorular hazırlayabilir, sınavlar oluşturabilir ve öğrenci ilerlemesini takip edebilirler. Eğitimi, kapsamlı bir öğrenme yönetim sistemi ile yükseltin ve ilgi çekici ve analitik bir öğrenme ortamı oluşturun!`
              },
              {
                rel: 'icon',
                href: domain + '/favicon_lgsxxlarge.ico'
              },
              {
                type: 'application/ld+json',
                json: {
                  "@context": "http://schema.org",
                  "@type": "EducationalOrganization",
                  "name": "LGS XXLARGE",
                  "url": domain,
                  // Diğer ilgili alanlar
                }
              },
              {
                name: 'robots',
                content: 'index, follow'
              },
              {
                name: 'keywords',
                content: 'Yapay zeka destekli quiz oluşturucu, LGS, etkileşimli öğrenme platformu, çevrimiçi quiz hazırlayıcı, eğitim teknolojisi araçları, e-öğrenme çözümleri, öğrenci değerlendirme yazılımı, özelleştirilebilir quizler, öğretmen kaynakları, otomatik quiz üretimi, eğitimsel analitik, LMS yazılımı, uzaktan öğrenme araçları, eğitimsel oyunlaştırma, etkileşimli video içerikleri, çevrimiçi öğrenme yönetim sistemi'
            },
              // Open Graph Etiketleri
              {
                property: 'og:title',
                content: 'Interaktif Quiz Maker - Öğrenciler ve Öğretmenler İçin LGS odaklı Eğitim Platformu'
              },
              {
                property: 'og:description',
                content: 'AI destekli Quiz Maker ile öğrenme deneyiminizi dönüştürün! Öğrenciler ve öğretmenler için etkileşimli quizler, kitaplar ve video içerikler oluşturun. Kişiselleştirilmiş öğrenme yoluyla eğitimi keşfedin.'
              },
              {
                property: 'og:type',
                content: 'website'
              },
              
              {
                property: 'og:url',
                content: domain
              },
              {
                property: 'og:image',
                content: domain +'/icons/lgsxxl-logo.png'
              },
            
              // Ekstra Etiketler
              {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1'
              },
              {
                name: 'author',
                content: 'UES - Sınırsız Eğitim Hizmetleri'
              },
              {
                name: 'language',
                content: 'Türkçe'
              },
            ],
            link: [
              {
                rel: 'canonical',
                href: domain
              }
            ]
        
        }
      
      

function getQuestionStandarts({role = 'super-admin'}) {
const userData = JSON.parse(sessionStorage.getItem("userData"));
const currentUserId = userData?.uesId || '';
const onlyConceptStandardShowUser = process.env.VUE_APP_ONLY_CONCEPT_STANDARD_SHOW_USER || ''; 
const institutionId = userData?.institutionId || '';
const campusId = userData?.institutionSubSchoolId || '';
const bilfenId = process.env.VUE_APP_BILFEN_ID || '';
const uesId = process.env.VUE_APP_TEKNOPARK_ID || '';
const schoolId = process.env.VUE_APP_SCHOOL_ID || '';
const conceptSchoolId = process.env.VUE_APP_CONCEPT_SCHOOL_ID  || '' ;
const assessmentCampusId = process.env.VUE_APP_ASSESSMENT_CAMPUS_ID || '';
const uesCollageSchool = process.env.VUE_APP_UES_COLLEGE_ID || null;
const unAllowedCampusToSeeAll = [assessmentCampusId]


const allowedSchoolAllStandards = [bilfenId,uesId,uesCollageSchool];

if(allowedSchoolAllStandards.includes(institutionId) && !unAllowedCampusToSeeAll.includes(campusId)){
  return [
    {
      key: "cefr",
      label: "CEFR",
    },
    {
      key: "lgs",
      label: "LGS",
    },
    {
      key: "lgs2",
      label: "LGS 2",
    },
    {
      key:'kw3',
      label:'KW3'
  },
  ]
}
else if(institutionId === schoolId){
  return [

    {
      key: "cefr",
      label: "CEFR",
    },

    {
      key: "lgs2",
      label: "LGS 2",
    },
  ]
}
else if(currentUserId  == onlyConceptStandardShowUser || conceptSchoolId == institutionId || assessmentCampusId == campusId){
  return [
    {
      key: "cefr",
      label: "CEFR",
    },
  ]
}
else {
  return [

    {
      key: "lgs2",
      label: "LGS 2",
    },
    {
      key: "lgs",
      label: "LGS",
    },
  ]
}


}

export default {
  questionStandarts:getQuestionStandarts
}
import axios from '@/services';
import getBrowserInfo from '@/helpers/getUserBrowserInfo';
import moment from 'moment';
function sendErrorToSlack(error) {

    const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera || '';
    const userId = JSON.parse(sessionStorage.getItem('userData'))?.uesId || '';
    const currentDate = moment().format();
    const detailError = error?.response?.data || ''
    let message = `HATA: ${error}-${detailError}; user_id:${userId}; user_agent: ${getBrowserInfo(userAgent)}; date:${currentDate}`;
    axios().post('general-usage/send-to-slack', {message})
    .then(() => console.log('Slack e hata bildirimi gönderildi.'))
    .catch(err => console.error('Slack hata bildirimi gönderilemedi:', err));
}

export default sendErrorToSlack
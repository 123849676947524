const getBrowserInfo = function(userAgent) {
  if (!userAgent) return 'Browser info not found';

  const isMobile = /Mobi|Android/i.test(userAgent);
  const isDesktop = !isMobile;
  let browser = "Unknown";
  let version = "Unknown";
  
  const browserVersionMap = [
    { regex: /MSIE|Trident/i, name: "Internet Explorer", versionRegex: /(MSIE |rv:)(\d+(\.\d+)?)/ },
    { regex: /Firefox/i, name: "Firefox", versionRegex: /Firefox\/(\d+(\.\d+)?)/ },
    { regex: /Chrome/i, name: "Chrome", versionRegex: /Chrome\/(\d+(\.\d+)?)/ },
    { regex: /Safari/i, name: "Safari", versionRegex: /Version\/(\d+(\.\d+)?)/ },
    { regex: /Edge/i, name: "Edge", versionRegex: /Edge\/(\d+(\.\d+)?)/ },
    { regex: /Opera|OPR/i, name: "Opera", versionRegex: /(Opera|OPR)\/(\d+(\.\d+)?)/ }
  ];

  for (let i = 0; i < browserVersionMap.length; i++) {
    const { regex, name, versionRegex } = browserVersionMap[i];
    if (regex.test(userAgent)) {
      browser = name;
      const versionMatch = userAgent.match(versionRegex);
      if (versionMatch && versionMatch.length >= 2) {
        version = versionMatch[2];
      }
      break; // Found the browser, no need to continue
    }
  }

  // Chrome's user agent includes "Safari", so check Chrome first
  if (browser === "Safari" && /Chrome/i.test(userAgent)) {
    browser = "Chrome";
    const chromeVersionMatch = userAgent.match(/Chrome\/(\d+(\.\d+)?)/);
    if (chromeVersionMatch && chromeVersionMatch.length >= 2) {
      version = chromeVersionMatch[1];
    }
  }

  return JSON.stringify({ 
    isMobile,
    isDesktop,
    browser,
    version
  });
}

export default getBrowserInfo;

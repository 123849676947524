const allowedRoles = ['student','teacher','campusadmin','schooladmin','super-admin'];
const allowedSchools = ["*"]; // "*" for all schools.
const uesAuthKey = process.env.VUE_APP_UESLMS_AUTH_KEY || null;

let allowedPlatformsToStudent = [
    'my-edu-quiz'
]
const allowedUserRules = {
       roles: allowedRoles,
       schools: allowedSchools,
       allowedPlatformsToStudent,
       uesAuthKey 
}

export default allowedUserRules;
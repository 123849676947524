import authRoleTypes from '@/constants/auth/authRoleTypes'
import seoSettingRules from './seoSettingsRules';


export default {
    appName : 'MyEDUQUIZ',
    appIcon: 'icons/myEDUQUIZ_logo.png',
    loginPageIcon: 'icons/myEDUQUIZ_logo.png',
    loginPageDesign:{
        formBgColor:`background: #403A3E;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #BE5869, #403A3E);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to top, #BE5869, #403A3E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `,
        loginPageBackgroundColor:`  background: #403A3E;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #BE5869, #403A3E);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to top, #BE5869, #403A3E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */`

    },

    generalRules:{
        isDefaultScoolVisible:true, // Teknopark by default visible or not check.
        questionRouteAllowedRoles: authRoleTypes.$authorizeToSuperAdmin,
        studentAssignedQuizListPage: {
            name:'my-assigned-quizzes',
            path:'/my-assigned-quizzes',
            domain:'myeduquiz.com',
            component:()=>import("@/components/students/my_assigned_quizzes/Index.vue"),
            screen:'student'
        }
    },

    seoSettings:seoSettingRules()
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import sendErrorToSlack from "./constants/app/slackErrorhandler"
import { createMetaManager } from 'vue-meta'
import authRoleTypes from './constants/auth/authRoleTypes'
import {QuillEditor} from '@vueup/vue-quill'
// require styles for Quill
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import "./assets/quil_css_modified/quill.core.css"
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeicons/primeicons.css'
import { settings } from './config/primeVueSetUp';
import { loadFonts } from './plugins/webfontloader'
loadFonts()
import Pagination from './components/paginations/Pagination.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VTooltip from 'v-tooltip'
import VuePlyr from 'vue-plyr'
import axios from "./services"
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import loading from "./components/utilComponents/loading.vue"
import { Container } from "vue-dndrop";
import { VueDraggableNext } from 'vue-draggable-next'
import VueLazyLoad from 'vue3-lazyload'
import WarningPopUp  from '@/components/utilComponents/warningPopUp.vue'
import ConfirmPopUp from '@/components/utilComponents/confirmPopUp.vue' 
import useVuelidate from "@vuelidate/core";
import KeyWordFilter from '@/components/utilComponents/KeyWordFilter.vue';
import ExportExcel from '@/components/utilComponents/ExportExcel.vue'
import Vue3Html2pdf from 'vue3-html2pdf'
import VueApexCharts from "vue3-apexcharts";
import EventBus from '@/helpers/globalEmiter';
import getUserActionInfo from '@/helpers/getUserActionInfo';
import getRules from '@/rules'
import getDemoStandard from '@/rules/demo_account_rules/index'
import ResponseMessagesBox from '@/components/utilComponents/response_message_box/IndexResponseBox.vue'
import localforage from 'localforage';
import { Bar,Doughnut,Line,Pie} from 'vue-chartjs'
import { Chart as ChartJS, Title, PointElement,ArcElement, Tooltip,LineElement, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, BarElement, LineElement,CategoryScale, LinearScale,PointElement)
import {
  // create naive ui
  create,
  // component
  NButton,
  NDropdown,
  NSelect,
  NTreeSelect,
  NSpace,
  NForm,
  NIcon,
  NInput,
  NInputNumber,
  NCountdown,
  NProgress,
  NAvatar,
  NTag,
  NPopover,
  NRadio,
  NRadioGroup,
  NCheckbox,
  NSwitch,
  NAlert,
  NSlider,
  NIconWrapper,
  NRadioButton,
  NPagination
 
} from 'naive-ui'

const naive = create({
  components: [NPagination,NButton,NRadioGroup,NRadioButton, NIconWrapper,NTag,NSwitch,NSlider, NPopover,NAlert,NCheckbox,NSelect,NTreeSelect,NSpace,NForm,NRadio, NIcon,NInput, NDropdown,NInputNumber,NCountdown,NProgress,NAvatar]
})

import 'vue-plyr/dist/vue-plyr.css'

const metaManager = createMetaManager()


 const vueApp = createApp(App).
   use(store)
  .use(naive)
  .use(VueApexCharts)
  .use(VueSweetalert2)
  .use(router)
  .use(metaManager)
  .use(PrimeVue)
  .use(VueLazyLoad)
  .use(VuePlyr, {
    plyr: {}
  })
  .use(VTooltip)
.use(DatePicker)



// Sentry.init({
//   app: vueApp,
//   dsn: "https://6f928623c3eae41dce7b6c8b51f6b583@o4506228600012800.ingest.sentry.io/4506228605648896",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

for (const key in settings) {
  if (settings.hasOwnProperty.call(settings, key)) {
   vueApp.component(key, settings[key].component);
  }
}

vueApp.provide("useVuelidate",useVuelidate);
vueApp.component('QuillEditor', QuillEditor);
vueApp.component('ResponseMessagesBox',ResponseMessagesBox);
vueApp.component('Pagination',Pagination)
vueApp.component("Loading",loading);
vueApp.component("Container",Container);
vueApp.component("Draggable",VueDraggableNext);
vueApp.component("DatePicker",DatePicker);
vueApp.component("WarningPopUp",WarningPopUp );
vueApp.component("ConfirmPopUp",ConfirmPopUp );
vueApp.component('KeyWordFilter', KeyWordFilter)
vueApp.component('ExportExcel', ExportExcel);
vueApp.component('Vue3Html2pdf', Vue3Html2pdf);
vueApp.component('BarChart', Bar);
vueApp.component('LineChart', Line)
vueApp.component('DoughnutChart', Doughnut);
vueApp.component('PieChart', Pie);


for (const role in authRoleTypes ) {
  vueApp.config.globalProperties[role] = authRoleTypes[role];
}
vueApp.config.globalProperties.$bus = EventBus 
vueApp.config.productionTip = false;
vueApp.config.globalProperties.$getUserActionInfo = getUserActionInfo;
vueApp.config.unwrapInjectedRef = true
vueApp.config.globalProperties.$axios = axios();
vueApp.config.globalProperties.$getRules = getRules;
vueApp.config.globalProperties.$getDemoStandard = getDemoStandard;
vueApp.config.globalProperties.$localforage = localforage;

vueApp.config.errorHandler = (err, instance, info) => {
  console.log(err, instance, info);
  sendErrorToSlack(`Global Hata Yakalandı: ${err.message}; component: ${instance.$.type.__file}; info: ${info}`); // Slack'e hata bildirimi gönder
  // Burada ek hata işleme adımlarınızı gerçekleştirebilirsiniz
};



vueApp.mount('#app')


import axios from '../../services';
import getRules from '@/rules'
import  currentDomain  from "@/helpers/getDomainName";
import localForage from "localforage";
import getSpesificStandardByUser from "@/constants/standards/indexStandards"
import _ from 'lodash';
const defaultState = {
    onProcessQuestion: {
        questionHeadData: {},
        questionAnswersData: {},
        questionSubDetails: {
            questionOptionLayoutType: 'default',
        }
    },

    readingQuestion:{
        activeContentPageStage:{
         pageId:'',
         pageIndex:null,
         pageType:'content',
         pageContentMediaItems:[],
         pageContentText:'',
         wordsTimestamps:[],
         translatedWordsObject:[]

        },
        activeQuizPageStage:{
            pageId:'',
            pageIndex:null,
            pageType:'quiz',
            pageQuizQuestions:[],
            pageQuizTitle:''    
        },

        pages:[]

    },
    myQuestionIds: [],
    outcomeNoneQuestions: [],
    selectedStudentQuizCreateQuestions: [],

    onUpdateQuestionInfo: {},

    onProcessQuestionFormData: {},
    onProcessQuestionFormDataStandard: 'cefr',

    schoolId: null,
    fetchedListQuestions: [],
    previewQuestionData: null,
    filteredQuestionCount: 0,

    checkedOutcomesToSelfGenerateQuiz: [],
    onProcessCreatedQuestions: [],
    selfQuizOutcomeQuestions: [],
}
const questionDefaultStandards = getRules(currentDomain,'question.questionStandartRules.questionStandarts').
map(standard => standard.key);

export default {
    namespaced: true,
    state: defaultState,

    mutations: {

        resetQuestionState(state){
            state = defaultState;
      
          },
        setOnProcessQuestionsBox(state, question) {
            state.onProcessCreatedQuestions.push(question[0])
        },
        removeOnProcessQuestionsBox(state, question) {

            state.onProcessCreatedQuestions = state.onProcessCreatedQuestions.filter(qs => {
                qs._id != question._id
            })
        },

        resetReadingQuestion(state){
          state.readingQuestion = {
            activeContentPageStage:{
             pageId:'',
             pageIndex:null,
             pageType:'content',
             pageContentMediaItems:[],
             pageContentText:'',
             wordsTimestamps:[],
             translatedWordsObject:[]   
            },
            activeQuizPageStage:{
                pageId:'',
                pageIndex:null,
                pageType:'quiz',
                pageQuizQuestions:[],
                pageQuizTitle:''    
            },
            pages:[]
          }  
        },
        setReadingQuestionActiveContentPage(state,contentObj){
            if(Object.keys(contentObj).length){
                for (const key in contentObj) {
                    state.readingQuestion.activeContentPageStage[key] = contentObj[key];
                }
            }
            else {
                state.readingQuestion.activeContentPageStage = {
                    pageId:'',
                    pageIndex:null,
                    pageType:'content',
                    pageContentMediaItems:[],
                    pageContentText:'',
                    wordsTimestamps:[],
                    translatedWordsObject:[]
                };
            }
            
        },

        setReadingQuestionActiveQuizPage(state,quizObj){
            if(Object.keys(quizObj).length){
                for (const key in quizObj) {
                    state.readingQuestion.activeQuizPageStage[key] = quizObj[key];
                }
            }
            else {
                state.readingQuestion.activeQuizPageStage = {
                    pageId:'',
                    pageIndex:null,
                    pageType:'quiz',
                    pageQuizQuestions:[],
                    pageQuizTitle:''
                };
            }
        },

        addReadingPage(state,page){
            const isPageIndexExist = state.readingQuestion.pages.find(itm => itm.pageIndex == page.pageIndex);
            const bookUniqueId = sessionStorage.getItem("bookUniqueId") || "";

            if(isPageIndexExist){

                const prevPages = [...state.readingQuestion.pages];

                prevPages.splice(page.pageIndex,0,page);
                state.readingQuestion.pages = prevPages.map((itm,index) => {
                    itm.pageIndex = index;
                    itm.translatedWordsObject = "book-page-" + bookUniqueId + "-" + index;
                    
                    return itm;
                });

            }
            else {
                state.readingQuestion.pages.push(page);

            }
        },
        editReadingPage(state,page){
            state.readingQuestion.pages[page.pageIndex] = page;
        },
        setAllReadingPages(state, pages){
            state.readingQuestion.pages = pages; 
        },
        async removeReadingPage(state,pageIndex){
            const bookUniqueId = sessionStorage.getItem("bookUniqueId") || "";

            await localForage.removeItem('book-page-' + bookUniqueId + '-' + pageIndex);
            const updatedPages = state.readingQuestion.pages.filter(p => p.pageIndex != pageIndex);

        
            for (let index = 0; index < updatedPages.length; index++) {
                const page = updatedPages[index];
        
                if (page.pageType === 'content') {
                    const translatedWordsId = `book-page-${bookUniqueId + '-' + page.pageIndex}`;
                    const translatedWords = await localForage.getItem(translatedWordsId);
        
                    if (translatedWords) {
                        await localForage.removeItem(translatedWordsId);
                        await localForage.setItem(`book-page-${bookUniqueId + '-' + index}`, translatedWords);
                    }
                }
        
                updatedPages[index] = {
                    ...page,
                    translatedWordsObject: `book-page-${bookUniqueId + '-' + index}`,
                    pageIndex: index
                };
            }
        
            state.readingQuestion.pages = updatedPages;
        },
        
        setUpdateQuestionInfo(state, info) {

            state.onUpdateQuestionInfo = info;

        },

    
        saveCustomizedQuestion(state, customizedHtml,questionType = "SingleChoice") {

            const singleLineQuestionTypes = ['unjumble',"anagram"];
            if(singleLineQuestionTypes.includes(questionType.toLowerCase())){
           
                state.onProcessQuestion.questionAnswersData.answersContent[0].data = customizedHtml;
           
            }
            else {
                state.onProcessQuestion.questionHeadData.headContent = customizedHtml;
                state.onProcessQuestion.questionStaticHTMLFormat = '';
            }
        


        },


     


        setOnProcessQuestionFormDataStandard(state, typeName) {
            state.onProcessQuestionFormDataStandard = typeName;
        },
        setOnProcessQuestionsFilter(state, { propertyName, data = null }) {


            if (data == 'reset') {
                state.onProcessQuestionFormData = {};


            }
            else if (propertyName == 'set-all-once') {

                state.onProcessQuestionFormData = data;
            }
            else if(propertyName === 'institutionId'){
                state.onProcessQuestionFormData = {};
                state.onProcessQuestionFormData['institutionId'] = data;
            }
            else if (propertyName === 'questionStandard') {
                if (state.onProcessQuestionFormData[propertyName] !== data) {
                    for (let key in state.onProcessQuestionFormData) {

                        if (!['questionStandard', 'institutionId'].includes(key)) {
                            delete state.onProcessQuestionFormData[key]
                        }
                    }
                }

                state.onProcessQuestionFormData[propertyName] = data;

            }
            else if (data) {
                state.onProcessQuestionFormData[propertyName] = data;
            }

            else {

                delete state.onProcessQuestionFormData[propertyName];

            }


        },

        resetOnProcessQuestionsBox(state) {
            state.onProcessCreatedQuestions = [];

        },

     

        resetOnProcessQuestion(state) {
            state.onProcessQuestion.questionHeadData = {};
            state.onProcessQuestion.questionAnswersData = {};
            state.onProcessQuestion.questionSubDetails = {};
            state.onProcessQuestion.customizedQuestion = {};
            delete state.onProcessQuestion.questionStaticHTMLFormat;
            delete state.onProcessQuestion.institutionId


        },


        setQuestionHeadData(state, { propertyName, data }) {

            state.onProcessQuestion.questionHeadData[propertyName] = data;


        },
        setQuestionStaticHTMLHeadData(state, data) {
            state.onProcessQuestion.questionStaticHTMLFormat = data;
        },
        setOnProcessQuestionsInstituId(state, id) {
            state.onProcessQuestion.institutionId = id;
        },


        setQuestionAnswersData(state, { propertyName, data }) {
              
                const questionTypeName = sessionStorage.getItem('currentQuestionType') || "single_choice";
                const questionTypesAnswerOptionHasNotDataField = ['true_or_false',"group_questions","interactive_video","bucket_question"];
                const currentQuestionAnswerOptionHasNotDataField = questionTypesAnswerOptionHasNotDataField.includes(questionTypeName.toLowerCase()); 
                if(data && Array.isArray(data)){

                const getFilledOption = currentQuestionAnswerOptionHasNotDataField ? data : data.filter(option => option?.data);
                
                if(getFilledOption.length > 0){
                    state.onProcessQuestion.questionAnswersData[propertyName] = getFilledOption;
                }
                else {
                    delete state.onProcessQuestion.questionAnswersData[propertyName];
                }
            }
           

        },

        setQuestionSubDetails(state, subDetail) {
            for (const key in subDetail) {

                state.onProcessQuestion.questionSubDetails[key] = subDetail[key];


            }

        },

        setQuestionOptionLayout(state, layout) {

            state.onProcessQuestion.questionSubDetails['questionOptionLayoutType'] = layout;
        },


        setFilteredQuestions(state, questions) {


            state.fetchedListQuestions = questions;
        },

        resetFilteredQuestions(state) {

            state.fetchedListQuestions = [];
        },
        setOptionsValues(state, optionsValues) {

            state.optionsValues = optionsValues;
        },
        setPreviewQuestionData(state, data) {
            state.previewQuestionData = data;
        },

        deleteQuestion(state, questionId) {
            state.fetchedListQuestions = state.fetchedListQuestions.filter(question => {

                return question._id != questionId;
            })
        },
        setFilteredQuestionTotalCount(state, questionsCount) {

            state.filteredQuestionCount = questionsCount;
        },
        setMyQuestionIds(state, ids) {
            state.myQuestionIds = ids
        },
        setNoneOutcomeQuestions(state, outcomeNoneQuestions) {
            if (outcomeNoneQuestions.length) {

                state.outcomeNoneQuestions = outcomeNoneQuestions.reduce((acc, itm) => {

                    if (acc.length) {
                        if (!acc.includes(itm)) {
                            acc.push(itm)
                        }
                    }
                    else {

                        acc.push(itm)
                    }

                    return acc;

                }, []);
            }
            else {
                state.outcomeNoneQuestions = outcomeNoneQuestions;
            }

        },

        setSelectedStudentQuizCreateQuestions(state, questions) {


                function isEqualIgnoringId(obj1, obj2) {
                    // _id alanını her iki nesneden de klonlayarak kaldır
                    const obj1WithoutId = _.omit(obj1, '_id');
                    const obj2WithoutId = _.omit(obj2, '_id');
                
                    // Klonlanan nesnelerin eşit olup olmadığını kontrol et
                    return _.isEqual(obj1WithoutId, obj2WithoutId);
                }
            
            const selectedQuestions = state.selectedStudentQuizCreateQuestions;
            
            const uniqueSelectedQuestions = questions.reduce((acc, itm) => {

                const existingItem = acc.find(item => isEqualIgnoringId(item, itm));
                if (!existingItem) {
                    acc.push(itm);
                }
                return acc;
            }, []);

         

            uniqueSelectedQuestions.forEach(question => {
                const existingItem = selectedQuestions.find(item => isEqualIgnoringId(item, question));

                if(!existingItem){
                state.selectedStudentQuizCreateQuestions.push(question);
                }
            });

        },
        removeSelectedStudentQuizCreateQuestions(state, questionId) {
            state.selectedStudentQuizCreateQuestions = state.selectedStudentQuizCreateQuestions.filter(x => x._id !== questionId);
        },
        setCheckedOutcomes(state, {isChecked,questionId}) {
            if(isChecked){
                state.checkedOutcomesToSelfGenerateQuiz.push(questionId);

            }
            else {
                state.checkedOutcomesToSelfGenerateQuiz = state.checkedOutcomesToSelfGenerateQuiz.filter(x => x !== questionId);
            }
        },

        removeAllCheckedOutcomes(state) {
            state.checkedOutcomesToSelfGenerateQuiz = [];
        },

        setSelfQuizOutcomeQuestions(state, questions) {
            state.selfQuizOutcomeQuestions = questions;
        },

        resetSelectedStudentQuizCreateQuestions(state) {
            state.selectedStudentQuizCreateQuestions = [];
        },





    },

    actions: {

        async getMyQuestionIds({ commit }, uesId) {

            try {
                let { status_code, results } = await axios().post('question/get-my-question-ids', { uesId });
                if (status_code === 200) {
                    commit('setMyQuestionIds', results)
                }
                return true

            } catch (error) {
                alert(error);
                return true;
            }


        },
        async getFilteredRandomQuestions({commit},filter){
            try {
                const result = await axios().post('question/get-random-questions',filter);
                if(result.status_code === 200){
                 return result?.questions || []
                }
                return result
            } catch (error) {
                console.log(error);
                return []
            }
            
        },

        async getFilteredOutcomesRandomQuestions({commit},filter){
            try {
                const result = await axios().post('question/get-self-quiz-random-outcome-questions-by-filter',filter);
                if(result.status_code === 200){
                 return result?.questions || []
                }
                return result
            } catch (error) {
                console.log(error);
                return []
            }
            
        },
        getByKeywordSearchQuestions({ commit, getters }, formInfos) {
            let standardForm = JSON.parse(JSON.stringify(getters['getProcessQuestionsForm']));
            const userData = JSON.parse(sessionStorage.getItem("userData"));
            const institutionId = userData?.institutionId || '';
            const campusId = userData?.institutionSubSchoolId || '';

            try {

                if (Object.keys(standardForm).length) {
                    formInfos = { ...standardForm, ...formInfos }
                    // formInfos.institutionId = standardForm?.institutionId;
                    // formInfos.questionStandard= standardForm?.questionStandard;



                }
                else {

                    formInfos.institutionId = "632c3940d39d21c40e18a012";
                    formInfos.questionStandard = 'lgs'
                }

                if (!formInfos?.paginateIndex || !formInfos.paginateIndex) {
                    formInfos.paginateIndex = 1;
                }

                const specificStandardsByUser = getSpesificStandardByUser(institutionId, campusId);
                if (specificStandardsByUser.data.length) {
                    formInfos['subLimitedStandards'] = specificStandardsByUser.filter;
                }

                formInfos['defaultStandards'] = questionDefaultStandards;
                return axios().post('question/get-question-by-keyword', formInfos).then(({ data, status_code }) => {

                    if (status_code == 200) {
                        commit("setNoneOutcomeQuestions", data?.outcomesOfNoneQuestion || [])
                        commit("setFilteredQuestionTotalCount", data.questionFilteredTotalCount)
                        commit("setFilteredQuestions", data.questionFilteredData);
                        let currentIndexValue = ((formInfos?.paginateIndex?.currentIndex || formInfos?.paginateIndex));

                        let modCurrentIndexTotalQuestionMode = data.questionFilteredTotalCount % (currentIndexValue * 20);

                        let currentIndexPlusMod = (currentIndexValue * 20) + modCurrentIndexTotalQuestionMode
                        let isCurrentIndexGrtAllQueestion = (Math.ceil((data.questionFilteredTotalCount / 20))) < currentIndexValue

                        //   || modCurrentIndexTotalQuestionMode == data.questionFilteredTotalCount)
                        if ((isCurrentIndexGrtAllQueestion && currentIndexPlusMod > data.questionFilteredTotalCount) && currentIndexValue > 1) {

                            commit('setPaginationIndex', { currentIndex: 1, currentDataGroupCount: 0 }, { root: true });
                        }



                        return true;
                    }

                    return false;

                }).catch(err => {
                    return err

                });


            } catch (error) {
                console.log(error)
                return error
            }

        },
        getFilteredQuestions({ commit, getters }, formInfos) {
            let standardForm = JSON.parse(JSON.stringify(formInfos));
            const userData = JSON.parse(sessionStorage.getItem("userData"));
            const institutionId = userData?.institutionId || '';
            const campusId = userData?.institutionSubSchoolId || '';
            const specificStandardsByUser = getSpesificStandardByUser(institutionId, campusId);

            if (specificStandardsByUser.data.length) {
                standardForm['subLimitedStandards'] = specificStandardsByUser.filter;
            }

            try {
                if (standardForm?.questionFilter && Object.keys(standardForm?.questionFilter).length > 1) {

                    for (const key in standardForm.questionFilter) {

                        if (!(standardForm.questionFilter[key].length)) {

                            delete standardForm.questionFilter[key];
                        }
                    }
                }

                else if (Object.keys(getters['getProcessQuestionsForm']).length) {

                    standardForm.questionFilter = {};
                    for (const key in getters['getProcessQuestionsForm']) {

                        if ((getters['getProcessQuestionsForm'][key].length)) {

                            standardForm.questionFilter[key] = getters['getProcessQuestionsForm'][key];
                        }
                    }


                }

                standardForm['defaultStandards'] = questionDefaultStandards;
                return axios().post('question/get-question', standardForm).then(({ data, status_code }) => {
                    if (status_code == 200) {
                        commit("setFilteredQuestions", data.questionFilteredData);
                        commit("setNoneOutcomeQuestions", data?.outcomesOfNoneQuestion || [])
                        commit("setFilteredQuestionTotalCount", data.questionFilteredTotalCount)
                        return true;
                    }

                    return false;

                }).catch(err => {
                    return err

                });


            } catch (error) {
                console.log(error)
                return error
            }

        },


        async getQuestionsByOutcome(context,outcomeFilter){
                try {
                    const result = await axios().post('question/get-questions-by-outcome',outcomeFilter);
                    const resObj = {
                        questions: result?.data?.questions || [],
                        totalQuestionCount: result?.data?.totalQuestionCount || 0
                    }
                    
                    return resObj;
                } catch (error) {
                        console.log(error)
                        return [];
                }
        },


        async questionDelete({ commit }, { _id, questMedia = [] }) {


            try {


                const { status_code } = await axios().post('/question/delete-question', { _id });

                if (status_code == 200) {
                    commit('deleteQuestion', _id);
                    // if(questMedia.length){
                    //     for (const {storagePath} of questMedia) {
                    //       try {
                    //         await axiosMedia.post('https://lgsxxlarge-api.ey.r.appspot.com/uploader/lms/delete', {
                    //             file_name: `lms${storagePath.split('lms')[1]}`,
                    //           })


                    //       } catch (error) {
                    //         console.log(error);
                    //         alert(error);
                    //       }

                    //     }

                    // }
                }

                return status_code;

            }
            catch (error) {
                console.log(error);
                alert(error);
                return false;
            }



        },


        createQestions({ commit }, questionsInfo) {

            return axios().post('/question/create-question', questionsInfo).then(res => {
                commit('resetOnProcessQuestion')
                if (res.status_code == 201) {
                    commit('setOnProcessQuestionsBox', res.question);
                    return true;
                }

                return false


            }).catch(err => {
                console.log(err)
                return false
            })



        },

        updateQuestion({ commit }, questionInfo) {

            return axios().post('/question/update-question', questionInfo).then(({ status_code }) => {
                commit('resetOnProcessQuestion')
                if (status_code == 200) return true;
                return false;

            }).catch(err => {

                return err;
            })

        }




    },

    getters: {
        getListQuestion(state) {

            return state.fetchedListQuestions;
        },
        getFilteredQuestionCount(state) {
            return state.filteredQuestionCount;
        },

        getOnProcessQuestionData(state) {

            return state.onProcessQuestion;
        },

        getOnProcessReadingQuestionPages(state) {
          return state.readingQuestion.pages  
        },

        getActivePage(state) {
         return state.readingQuestion.activeContentPageStage;   
        },
        getActiveQuizPage(state) {
            return state.readingQuestion.activeQuizPageStage
        },
        getQuestionOptionLayout(state) {
            return state.onProcessQuestion?.questionSubDetails?.questionOptionLayoutType || 'default';
        },

        getUpdateQuestionInfo(state) {
            return state.onUpdateQuestionInfo
        },

        getMyQuestionIds(state) {
            return state.myQuestionIds;
        },

        getPreviewQuestionData(state) {
            return state.previewQuestionData;
        },
        getOnProgressCreatedQuestions(state) {
            if (state.onProcessCreatedQuestions.length) {
                return state.onProcessCreatedQuestions.filter(qs => qs);
            }
            return state.onProcessCreatedQuestions;
        },
        getProcessQuestionsForm(state) {
            return state.onProcessQuestionFormData;
        },

 

        getOnProcessQuestionFormDataStandard(state) {
            return state.onProcessQuestionFormDataStandard;
        },

        getOutcomeNoneQuestions(state) {
            if (state.outcomeNoneQuestions.length) {
                return state.outcomeNoneQuestions.map(outcome => {
                    return { key: outcome, label: outcome }
                })
            }
            return state.outcomeNoneQuestions;
        },

        getSelectedStudentQuizCreateQuestions(state) {
            return state.selectedStudentQuizCreateQuestions
        },

        getCheckedOutcomesToSelfGenerateQuiz(state){

            return state.checkedOutcomesToSelfGenerateQuiz
        },
        getSelfQuizOutcomeQuestions(state){
            return state.selfQuizOutcomeQuestions
        }





    },

}
function getQuestionStandarts({role = 'super-admin'}) {

    return [
   
 

      {
        key: "lgs2",
        label: "LGS 2",
      },
  
   
    
    ]
  }
  
  export default {
    questionStandarts:getQuestionStandarts
  }
<template>
  <div class="paginator-container"  >
    <div class="prev" @click="updateQuestionList('prev')">Prev</div>

    <div
      v-for="(indexNumber,index) in (10)"
    
      :key="(indexNumber)+dataGroupCount+index"
   
  
    >
  
    <div v-if="Math.ceil(filteredPaginateItemCount / 20) >= (indexNumber + dataGroupCount)"  
   
      :style="
        indexNumber + dataGroupCount == currentIndex
          ? { background: '#202d42', color: '#fff' }
          : { background: 'transparent', color: '#202d42' }
      "
      class="indexes"
        @click="updateQuestionList(indexNumber)" >

      {{ (indexNumber + dataGroupCount)}}
     
    </div>
   
    </div>

    <div class="next" @click="updateQuestionList('next')">Next</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props:{
    paginateCath:{
      type:String,
      default:'question'
    }
  },

  data(){
    return {
      dataGroupCount:0,
      currentIndex:1,
      totalGroupCount:0,
   
      filteredPaginateItemCount:0
    }
  },

  watch:{
    paginateInfo:{
      handler(val){
        if(val?.currentIndex === 1){
          this.dataGroupCount = 0;
        }
        
        this.currentIndex = val?.currentIndex || 1;

        
      },
      deep:true
    }
  },
  beforeMount(){
    if(this.paginateInfo){
      let {currentIndex,currentDataGroupCount} = this.paginateInfo;
      this.currentIndex = currentIndex ?? 1;
      this.dataGroupCount = currentDataGroupCount ?? 0;
      
    }
    if(this.paginateCath === 'question' ){
 
      this.filteredPaginateItemCount = this.$store.getters['question/getFilteredQuestionCount']
   
    }
    else if(this.paginateCath === 'quiz'){
      this.filteredPaginateItemCount = this.$store.getters['quiz/getQuizCount']
    }
    else if(this.paginateCath === 'book'){
      this.filteredPaginateItemCount = this.$store.getters["quiz/getTotalBookCount"]
    }
    else if(this.paginateCath === 'user_list'){
      this.filteredPaginateItemCount = this.$store.getters['user/getTotalUserCount']
    }
    
    if(this.filteredPaginateItemCount){
      this.totalGroupCount = Math.ceil(this.filteredPaginateItemCount / 20)
    }
  },
  methods:{
    updateQuestionList(index) {
      
      if (typeof index == "number") {
       this.currentIndex =  index + this.dataGroupCount;
      } else if (index == "prev") {
      
        if(this.currentIndex - this.dataGroupCount == 1){
          if(this.dataGroupCount == 0 && this.currentIndex == 1 ){
            this.currentIndex =  this.totalGroupCount;
            this.dataGroupCount = Math.floor(this.totalGroupCount/10)*10
          }
          else {
            this.dataGroupCount-=10;
          this.currentIndex = this.currentIndex -1
          }
         
        }
        else {
          this.currentIndex =  (this.currentIndex-1) 
        }
        

      } else {
       
        if(this.currentIndex - this.dataGroupCount == 10 || this.currentIndex == this.totalGroupCount){
        
          if(this.totalGroupCount - this.dataGroupCount <=10  && this.currentIndex == this.totalGroupCount ){
            this.currentIndex =  1;
            this.dataGroupCount = 0
          }
          else {
            this.dataGroupCount+=10;
          this.currentIndex = this.currentIndex +1
          }
         
        }
        else {
          this.currentIndex =  (this.currentIndex+1) 
        }
      }
      
      this.$store.commit('setPaginationIndex',{currentIndex:this.currentIndex,currentDataGroupCount:this.dataGroupCount});
    },
  },
  computed:{
...mapGetters({paginateInfo:'getPaginationIndex', questionForm: "question/getProcessQuestionsForm"}),

  },

};
</script>

<style scoped lang="scss">
.paginator-container {
  display: flex;
  justify-self: center;
  justify-content: center;
  width: 100%;
  flex-flow: row wrap;
  min-height: 40px;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  row-gap: 20px;
  border: 0;
 

  div {
    display: flex;
    text-align: center;
    align-items: center;
    height: 25px;
    width: fit-content;
   
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    border: 0;
    border-radius: 4px;
    color: #712581;
    cursor: pointer;
  

      & .indexes {
        padding: 16px ;
        width: fit-content;
        border: 1px solid #dee2e6;
        margin-left: 4px;
        margin-right: 4px;
        margin-top: 10px;
       
      }
    /* identical to box height, or 100% */

    /* Grey2 */
  }
}

.next {
  color: rgb(189, 21, 21) !important;
  margin-top: 5px;
  margin-left: 5px;
}

.prev {
  color: rgb(189, 21, 21) !important;
  margin-top: 5px;
  margin-right: 5px;
}
</style>
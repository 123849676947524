import bilfenRules from "./bilfen_rules";
import defaultRules from "./default_rules";
import myeduconceptRules from "./myeduconcept_rules";
import lgsxxlargeRules from "./lgsxxlarge_rules";
const customizeAppViaDomain = {
'www.gr8vault.com': bilfenRules,
'www.myeduquiz.com': defaultRules,
'www.lgsxxlarge.com':lgsxxlargeRules,
'www.myeduconcept.com':myeduconceptRules
}

function getRules(domainName,rulesPath,domainParams = {}){
    const domainFeatures = customizeAppViaDomain[domainName];
    
    if (!domainFeatures) {
        alert('please give a correct domain name')
        return false;
    }

    if (!rulesPath) {
        alert('please give a correct rules path')
        return false;
    }

    let properties = rulesPath.split('.');
    let result = domainFeatures;
    
    if(properties.length <= 1){
        return false;
    }




     for (let i = 0; i < properties.length; i++) {
        if (result.hasOwnProperty(properties[i])) {
            result = result[properties[i]];
          }
        else {
            result = undefined;
            break;
        }
      }
    
      if(typeof result === 'function'){
        return result(domainParams);
      }

      return result;
        
    
}

export default getRules;
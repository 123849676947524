
function getQuizPlatforms({role = 'super-admin'}) {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const institutionId = userData?.institutionId || '';
  const campusId = userData?.institutionSubSchoolId || '';
  const userRole = userData?.role || '';
  const userClasses = userData?.classes || [];
  const bilfenId = process.env.VUE_APP_BILFEN_ID || '';
  const uesId = process.env.VUE_APP_TEKNOPARK_ID || '';
  const uesCollege = process.env.VUE_APP_UES_COLLEGE_ID || '';
  const schoolId = process.env.VUE_APP_SCHOOL_ID || '';
  const uesCollageSchool = process.env.VUE_APP_UES_COLLEGE_ID || null;
  const allowedSchoolAllStandards = [uesId,uesCollageSchool];
  const assessmentCampusId = process.env.VUE_APP_ASSESSMENT_CAMPUS_ID || '';
  const conceptSchoolId = process.env.VUE_APP_CONCEPT_SCHOOL_ID  || '' ;
  const unAllowedCampusToSeeAll = [assessmentCampusId]



  let allowedPlatforms = [];
  if(allowedSchoolAllStandards.includes(institutionId) && !unAllowedCampusToSeeAll.includes(campusId)){
    allowedPlatforms = [
      {
        key: 'my-edu-quiz',
        label: 'My Edu Quiz'
      },
      {
        key: 'kw3',
        label: 'KW 3.0'
      },
    
      {
        key: 'LGSXXLARGE',
        label: 'LGSXXLARGE'
      },

      {
        key: 'my-edu-concept',
        label: 'My Edu Concept'
      }
    ]
  }
  else if (institutionId == uesCollege || institutionId == schoolId || conceptSchoolId == institutionId) {
    allowedPlatforms = [
      {
        key: 'my-edu-quiz',
        label: 'My Edu Quiz'
      },
    ]

    if(userRole === 'teacher'){
      const allowedClassesToTeacher = ['5','6','7'];
      const isTeacherHasEightgrandClass = userClasses.find(cls => allowedClassesToTeacher.includes(cls[0]))

      if(isTeacherHasEightgrandClass){
        allowedPlatforms.push(    {
          key: 'my-edu-concept',
          label: 'My Edu Concept'
        },)
      }
   
    }
    else {
      allowedPlatforms.push({
        key: 'my-edu-concept',
        label: 'My Edu Concept'
      },)
    }
}
  else {

    allowedPlatforms = [
      {
        key: 'my-edu-quiz',
        label: 'My Edu Quiz'
      },
       
  
    ]

    if(userRole === 'teacher'){
      const isTeacherHasEightgrandClass = userClasses.find(cls => cls[0] == '8')

      if(isTeacherHasEightgrandClass){
        allowedPlatforms.push(    {
          key: 'LGSXXLARGE',
          label: 'LGSXXLARGE'
        },)
      }
   
    }
    else {
      allowedPlatforms.push({
        key: 'LGSXXLARGE',
        label: 'LGSXXLARGE'
      },)
    }
  }
  return allowedPlatforms;
 }

export default getQuizPlatforms;
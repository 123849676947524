const assessments = ['myEDUASSESSMENT-middleschool','myEDUASSESSMENT-primaryschool','myEDUASSESSMENT-highschool'];
const concepts = ['Concept 1','Concept 2', 'Concept 3'];
const assessmentCampusId = process.env.VUE_APP_ASSESSMENT_CAMPUS_ID || '';
const conceptSchoolId = process.env.VUE_APP_CONCEPT_SCHOOL_ID  || '' ;
const schools = [conceptSchoolId];
const camouses = [assessmentCampusId];

const getStandards = (institutionId = '',campusId = '')=>{
    let typeOfId = '';
    if(camouses.includes(campusId)){
        typeOfId = 'campus'
    }
    else if(schools.includes(institutionId)){
        typeOfId = 'school'
    }
    const defaultValue = {
        data:[],
        filter :{
            field:'none',
            value:[],
            standard:'none'
        }
    };
    if('school' == typeOfId){
        if(institutionId === conceptSchoolId){
            return{
                data:concepts,
                filter :{
                    field:'resource',
                    value:concepts,
                    standard:'cefr'
                }
            }
        }

        return defaultValue
    }else if('campus' == typeOfId){
        if(assessmentCampusId == campusId){
            return {
                data:assessments,
                filter :{
                    field:'resource',
                    value:assessments,
                    standard:'cefr'
                }
            }
        }
                return defaultValue

    }
            return defaultValue

}

export default getStandards


import question from "./question_rules";
import quiz from "./quiz_rules"
import app from "./app_rules"
import allowedUserRules from "./allowed_user_rules";


export default { 
   question, 
   quiz,
   app,
   allowedUserRules
}
<template>
    <div class="non-exist-box-container">
     
         <img class="non-exist-icon" src="/icons/general_icons/non-exist-icon.png" alt="non.exist.icon">
 
     
     <p class="messages"> {{ responseStatusMessages }} </p> 
    </div>
 
 </template>
 
 <script>
 export default {
 props:{
   responseStatusMessages:{
       type:String,
       default:''
   }
 }
 }
 </script>
 
 <style scoped lang="scss">
 
 .non-exist-box-container {
     width: 100%;
     height: 100%;
     display: flex;
     flex-flow: row;
     align-items: center;
     justify-content: center;
     gap: 2%;
     border: 1px solid #97979754;

 
 }
 
 .non-exist-icon {
 height: 58px;
 width: 58px;
 
 filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, .25));
 
 
 }
 
 .messages {
    width: max-content;
     font-size: .85rem;
     font-weight: bold;
     color: #0d0f27d0;
     margin-top: 10px;
 }
 </style>
import authRoleTypes from '@/constants/auth/authRoleTypes';
import seoSettingRules from './seoSettingsRules';
export default {
    appName : 'gr8vault',
    appIcon: 'icons/gr8vault_bilfen_icon.png',
    loginPageIcon: 'icons/gr8vault_bilfen_icon.png',
    loginPageDesign:{
        formBgColor:`background: #1565C0;  /* fallback for old browsers - Denim */
        background: -webkit-linear-gradient(to right, #42A5F5, #2981e6);  /* Chrome 10-25, Safari 5.1-6 - Clear Day to Denim */
        background: linear-gradient(to bottom, #42A5F5, #2981e6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ - Clear Day to Denim */        
        `,

        loginPageBackgroundColor:`background: linear-gradient(to bottom, #262663 50%, #1b1b1bbb); /* Modern tarayıcılar için standart değer */
        background: -webkit-linear-gradient(top, #262663 50%, #1b1b1bbb); /* Chrome 10-25, Safari 5.1-6 için */
        background: -moz-linear-gradient(top, #262663 50%, #1b1b1bbb); /* Firefox 3.6-15 için */
        background: -o-linear-gradient(top, #262663 50%, #1b1b1bbb); /* Opera 11.10-12.00 için */
        background: -ms-linear-gradient(top, #262663 50%, #1b1b1bbb); /* Internet Explorer 10 için */`
    },
    generalRules:{
        isDefaultScoolVisible:true, // UES by default visible or not check.
        questionRouteAllowedRoles:authRoleTypes.$authorizeToSuperAdmin,
        studentAssignedQuizListPage: {
            name:'my-assigned-quizzes',
            path:'/my-assigned-quizzes',
            domain:'gr8vault.com',
            component:()=>import("@/components/students/my_assigned_quizzes/Index.vue"),
            screen:'student'
        }

    },

    seoSettings:seoSettingRules() 

    

}
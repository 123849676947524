import axios from '@/services';
import CryptoJS from 'crypto-js';
import localForage from "localforage"
import { cloneDeep } from 'lodash';
import domainNameByPlatform from '@/constants/app/domainNameByPlatforms';
import  currentDomain  from "@/helpers/getDomainName";

const defaultState = {
    selectedQuizInfo:{},
    quizQuestions:[],
    quizResult:null,
    selectedQuizAnswers:[],
    quizStat:{},
    selectedQuizCount:0,
    perQuestionsTime:[],
    completedQuizzes:[],
    selectedCompletedQuizId:'',
    currentGQSubIndex:0,
    quizBannerShowedStatus:false,
    selectedConceptResource : null,
    selectedConceptQuizzes:[],
    selectedConceptQuizzesTotalCount:0
}
export default { 

    namespaced: true,
    state: defaultState,
    mutations:{
        resetStudentQuizState(state){
            state = defaultState;
      
          },
        setInteractiveVideoFalseAttempt(state,questionIndex){
            if(state.selectedQuizAnswers.length){
                let question = state.selectedQuizAnswers[questionIndex];
                question.falseAttempts = question?.falseAttempts + 1;
            }
        
        },
        setSelectedQuizInfo(state,info){
            state.selectedQuizInfo = info ;
            
        },

        setQuizPerQuestionTime(state,{value,index}){
            if(value === 'reset'){
                state.perQuestionsTime = []
            }
            else if(value){
                const additionTime = value;
                state.perQuestionsTime[index] += additionTime; 
            }
                
        },
        setQuizStat(state,{property,value}){
            
            if(value ==='reset' && property === null){
                state.quizStat = {};
            }
            else {
                state.quizStat[property] = value;
            }

           
            

    },

    resetQuizQuestionsTimes(state){
        state.perQuestionsTime = [];
    },

        setQuizResult(state,result){
                state.quizResult = result ;

        },



     
        async setSelectedQuizQuestions(state,questions = []){
            async function storeFileInIndexedDB(data, filename) {
                await localForage.setItem(filename, data);
              }

           
              if(questions.length){
                questions.forEach((itm,index)=>{
                    if(itm?.questionTypeName == 'Dropdown'){
                        state.selectedQuizAnswers.push({
                    
                            score:0,userAnswer:null, correctAnswer: itm?.questionAnswersData?.answersCorrect ?? []
                          
                        })  
                    }
                    else if(itm?.questionTypeName == 'InteractiveVideo'){
                      
                        itm?.questionAnswersData?.answersContent.forEach((qs,ind)=>{
                            
                                if(ind === 0){
                                  
                                    state.selectedQuizAnswers.push( {
                                        type:'groupSubQuestion',
                                        groupIndexOrder:index,
                                        currentQuestionIndex:ind,
                                        falseAttempts:0,
                                        score:0,
                                        userAnswer:itm?.questionAnswersData?.answersContent.map(ans=>{
                                            return {score:0,userAnswer:null, correctAnswer:ans?.questionCorrectAnswer}
                                        }),
                                        correctAnswer:itm?.questionAnswersData?.answersContent ?? []
                                      
                                    }
                                    )
                                    
                                }
                                else {
                                   
                                    state.selectedQuizAnswers.push( {
                    
                                        type:'groupSubQuestion',
                                        groupIndexOrder:index,
                                        currentQuestionIndex:ind
                                      
                                    }
                                    )
                                   
                                }
                           
                        })
                    }
                    else if( itm?.questionTypeName == 'GroupQuestions' ){
                      
                        itm?.questionAnswersData?.answersContent.forEach((qs,ind)=>{
                            
                                if(ind === 0){
                                    state.selectedQuizAnswers.push( {
                                        type:'groupSubQuestion',
                                        groupIndexOrder:index,
                                        currentQuestionIndex:ind,
                                        
                                        score:0,
                                        userAnswer:itm?.questionAnswersData?.answersContent.map(ans=>{
                                            return {score:0,userAnswer:null, correctAnswer:ans?.questionCorrectAnswer}
                                        }),
                                        correctAnswer:itm?.questionAnswersData?.answersContent ?? []
                                      
                                    }
                                    )
                                    
                                }
                                else {
                                   
                                    state.selectedQuizAnswers.push( {
                    
                                        type:'groupSubQuestion',
                                        groupIndexOrder:index,
                                        currentQuestionIndex:ind
                                      
                                    }
                                    )
                                   
                                }
                           
                        })
                    }
                    else if(itm?.questionTypeName == 'Reading'){
                        itm?.questionAnswersData?.forEach((qs)=>{
                            if(qs?.pageType === "quiz"){
                                state.selectedQuizAnswers.push( {
                                    pageIndex:qs?.pageIndex,
                                    score:0,
                                    userAnswers:qs?.pageQuizQuestions.map(ans=>{
                                        return {score:0,userAnswer:null, correctAnswer:ans?.questionCorrectAnswer}
                                    }),
                                    correctAnswers:qs?.pageQuizQuestions.map(itm => itm?.questionCorrectAnswer) ?? []
                                  
                                }
                                )
                            }
                        })

                        
                    }
                    else {

                    if(itm?.questionSubDetails){
                        state.selectedQuizAnswers.push(  {
                    
                            score:0,userAnswer:null, correctAnswer:itm.questionSubDetails?.correctAnswer  ?? ''
                          
                        }
                        )
                    }
                    else {
                        state.selectedQuizAnswers.push(  {
                    
                            score:0,userAnswer:null, correctAnswer: (itm?.questionAnswersData?.answersContent || []).map(itm => itm?.data || itm) ?? ''
                          
                        }
                        )
                    }
                    

                }
                    
                })

              
                state.quizQuestions = questions.map(question=>{
                    
                    if((question?.questionAnswersData || []).length && question.questionTypeName === 'Reading'){
                        question.questionAnswersData = question.questionAnswersData.map((answer,index)=>{
                      if(answer?.pageType === 'content' && (answer?.translatedWordsObject || []).length){
                            const translatedWordsId = `book-page-${answer?.pageIndex || index}`;
                            const copiedObject = cloneDeep(answer.translatedWordsObject);
                         
                            storeFileInIndexedDB(copiedObject, translatedWordsId);
                            answer.translatedWordsObject = translatedWordsId; 
                            return answer;
                      }
                          else return answer;
                        })
                      }
                      return question;
                });
               
              }
           
        },

        setSelectedQuestionCount(state,length){
            state.selectedQuizCount = length ;
        },
        setBannerShowed(state,status){
          
            state.quizBannerShowedStatus= status;
        },
        setSelectedAnswers(state,{index,result}){
           
                state.selectedQuizAnswers[index] = result;
 
        },

        setSelectedAnswersOfBookPage(state,{pageIndex,questionIndex,answerDetail}){
            const pageItemIndex = state.selectedQuizAnswers.findIndex(item=>item?.pageIndex === pageIndex);
            if(pageItemIndex !== (-1) && pageItemIndex !== null){
                state.selectedQuizAnswers[pageItemIndex].userAnswers[questionIndex] = answerDetail;
                const totalScoreOfPageQuiz = state.selectedQuizAnswers[pageItemIndex].userAnswers.reduce((acc,itm)=>{
                    return acc + itm.score;
                },0)
                state.selectedQuizAnswers[pageItemIndex].score = totalScoreOfPageQuiz
            }


    },

        resetSelectedAnswers(state){
            state.selectedQuizAnswers = []
        },

        setStudentCompletedQuizzes(state,quizzes){
            state.completedQuizzes = quizzes;
        },

        setSelectedCompletedQuizId(state,_id){
            state.selectedCompletedQuizId = _id
        },
        updateGroupSubQuestionIndex(state,index){
            state.currentGQSubIndex = index;
        },

        setSelectedConceptResource(state,resource){
            state.selectedConceptResource = resource;
        },

        setSelectedConceptQuizzes(state,quizzes){
            state.selectedConceptQuizzes = quizzes;
        },

        setSelectedConceptQuizzesTotalCount(state,count){
            state.selectedConceptQuizzesTotalCount = count;
        },


        
    },

    actions:{
        async setStudentQuizQuestions({commit,state},quiz){

                    let {quizStudent:{quizQuestions = []},status_code} = await axios().post('quiz/get-quiz-student',quiz);
                   
                    const key = process.env.VUE_APP_API_KEY_STUDENT_QUESTION
                  
                
                
                  if(status_code==200){

                    const {selectedQuestions = []} = await axios().post('question/get-questions-byId/student',quizQuestions)
                    let decryptedQuestions = CryptoJS.AES.decrypt(selectedQuestions, key).toString(CryptoJS.enc.Utf8);
                    decryptedQuestions = JSON.parse(decryptedQuestions)
                   
                    if(decryptedQuestions.length){
                     if(decryptedQuestions[0]?.questionTypeName === 'Reading'){
                        decryptedQuestions[0].questionAnswersData
                        .forEach((qst,index)=>{
                            state.perQuestionsTime[index] = 0;
                        })
                     }
                     else {
                        decryptedQuestions.forEach((qst,index)=>{
                            state.perQuestionsTime[index] = 0;
                        })
                     }
                      
                    }
                  commit('setSelectedQuizQuestions',decryptedQuestions);

                  
                  return status_code ;
                }

                return status_code;

                    
        },
        async setStudentSelfQuizQuestions({commit,state},quizQuestions){
                    
           
            const key = process.env.VUE_APP_API_KEY_STUDENT_QUESTION
          

            const {selectedQuestions = []} = await axios().post('question/get-questions-byId/student',quizQuestions)
            let decryptedQuestions = CryptoJS.AES.decrypt(selectedQuestions, key).toString(CryptoJS.enc.Utf8);
            decryptedQuestions = JSON.parse(decryptedQuestions)
           
            if(decryptedQuestions.length){
             if(decryptedQuestions[0]?.questionTypeName === 'Reading'){
                decryptedQuestions[0].questionAnswersData
                .forEach((qst,index)=>{
                    state.perQuestionsTime[index] = 0;
                })
             }
             else {
                decryptedQuestions.forEach((qst,index)=>{
                    state.perQuestionsTime[index] = 0;
                })
             }
              
            }
          commit('setSelectedQuizQuestions',decryptedQuestions);

          
          return true ;
        

  

            
},

        async saveQuiz({commit,getters,state}){

           
            const solvedPlatform = domainNameByPlatform[currentDomain]  || 'my-edu-quiz';

            let {_id:solvedQuizId,dueDate   = '',startDate =  '',quizAssignedDate = new Date(), quizCalculationOfResultType = '3/1'} = getters['getSelectedQuizInfo']
            let studentAnswers = getters['getSelectedAnswers'];
            studentAnswers = studentAnswers.filter(ans=>{
                return  (ans?.currentQuestionIndex ?? 0) == 0
            })
            
            let quizQuestions = getters['getSelectedQuizQuestions']
            let quizStat = cloneDeep(getters['getQuizStat']);
            quizStat['questionTimes']  = cloneDeep(getters['getQuizQuestionTimes']);
     
            let {role,uesId,institutionId,username,institutionSubSchoolId,userSubDetail:{institutionName,subSchoolName},studentLastName,studentName,className} = JSON.parse(sessionStorage.getItem('userData')); 
            let result = {
                totalScore:0,
                scoreByQuestions:[],
                studentAnswers,
                quizStat,
                quizQuestions,
            }

            studentAnswers.forEach(answer=>{
                
             
                    let answerScore = answer?.score || 0;
                    if(answer?.falseAttempts && answerScore >= 0){
                        let questionCount = (answer?.userAnswer || []).length;
                        let maxFalseAnswerCount = questionCount*3;

                        if(answer.falseAttempts >= maxFalseAnswerCount ){
                            answerScore = 0;
                        }
                        else {
                            let penalty = answer.falseAttempts / questionCount;
                            penalty = penalty * (answerScore/questionCount);
                            penalty = Math.floor(penalty * 100) / 100;
                            answerScore =  answerScore - penalty;
                        }
                        
                    }
                    result.totalScore+=answerScore;
                    if(result.totalScore>100) result.totalScore = 100;
                    result.scoreByQuestions.push(answerScore);
                
               

            })
            if(quizCalculationOfResultType == '3/1'){
                let incorrectCount = result.scoreByQuestions.filter(rst=>rst === 0).length / 3; 
            result.totalScore -= +(+(100/result.quizQuestions.length).toFixed(2) * incorrectCount).toFixed(2);
            if(result.totalScore < 0){
                result.totalScore  = 0;
            }

            }
            
            
            commit('setQuizResult',result);
            

            const res = await axios().post('quiz/student-set-quiz-result',{
                assignedQuizId:solvedQuizId,
                studentAnswers,
                institutionId,
                dueDate,
                quizAssignedDate,
                startDate,
                institutionSubSchoolId,
                totalScore:result.totalScore,
                solvedPlatform,
                result,
                studentInfo: {role,uesId:String(uesId),institutionName,subSchoolName,studentLastName,studentName,className,username}
            }) 
            commit('resetQuizQuestionsTimes');
            commit('setQuizStat',{property:null,value:'reset'})
            commit('setSelectedQuizInfo',{})
            commit('setSelectedQuizQuestions',[])
            commit('setSelectedQuestionCount',0)
            commit('resetSelectedAnswers',[]);

            if(quizQuestions.length){

                try {
                    const isQuizTypeBook = quizQuestions[0]?.questionTypeName === 'Reading';
                    if(isQuizTypeBook){
                        let interactedBookWords = sessionStorage.getItem('interactedBookWords');
                        const language = 'en';
                        if(interactedBookWords){
                            interactedBookWords = interactedBookWords.split('&&');
                            const interactedBookWordsObj = {
                                interactedWords:interactedBookWords,
                                language,
                                uesId
                            };
                            axios().post('quiz/set-interacted-book-words',interactedBookWordsObj)
                        }
                       

                    }
                } catch (error) {
                    console.log(error);
                }
                finally {
                    sessionStorage.removeItem('interactedBookWords');
                }
            }
       

           return res;

           

        },
        async saveSelfGeneratedQuiz({commit,getters,state}){

           
            let {_id:solvedQuizId,quizName = ''} = getters['getSelectedQuizInfo']
            let studentAnswers = getters['getSelectedAnswers'];
            studentAnswers = studentAnswers.filter(ans=>{
                return  (ans?.currentQuestionIndex ?? 0) == 0
            })
            
            let quizQuestions = getters['getSelectedQuizQuestions']
            let quizStat = cloneDeep(getters['getQuizStat']);
            quizStat['questionTimes']  = cloneDeep(getters['getQuizQuestionTimes']);
     
            let {role,uesId,institutionId,username,institutionSubSchoolId,userSubDetail:{institutionName,subSchoolName},studentLastName,studentName,className} = JSON.parse(sessionStorage.getItem('userData')); 
            let result = {
                totalScore:0,
                scoreByQuestions:[],
                studentAnswers,
                quizStat,
                quizQuestions: quizQuestions.map(q=>q._id),
            }

            studentAnswers.forEach(answer=>{
                
             
                    let answerScore = answer?.score || 0;
                    if(answer?.falseAttempts && answerScore >= 0){
                        let questionCount = (answer?.userAnswer || []).length;
                        let maxFalseAnswerCount = questionCount*3;

                        if(answer.falseAttempts >= maxFalseAnswerCount ){
                            answerScore = 0;
                        }
                        else {
                            let penalty = answer.falseAttempts / questionCount;
                            penalty = penalty * (answerScore/questionCount);
                            penalty = Math.floor(penalty * 100) / 100;
                            answerScore =  answerScore - penalty;
                        }
                        
                    }
                    result.totalScore+=answerScore;
                    if(result.totalScore>100) result.totalScore = 100;
                    result.scoreByQuestions.push(answerScore);
                
               

            })
  
            
            
            commit('setQuizResult',result);
            

            const res = await axios().post('quiz/student-set-self-generated-quiz-result',{
                solvedQuizId,
                studentAnswers,
                institutionId,
                quizName,
                institutionSubSchoolId,
                totalScore:+(result.totalScore),
                result,
                studentInfo: {role,uesId:String(uesId),institutionName,subSchoolName,studentLastName,studentName,className,username}
            }) 
            commit('resetQuizQuestionsTimes');
            commit('setQuizStat',{property:null,value:'reset'})
            commit('setSelectedQuizInfo',{})
            commit('setSelectedQuizQuestions',[])
            commit('setSelectedQuestionCount',0)
            commit('resetSelectedAnswers',[]);

           return res;

           

        },

    async getStudentCompletedQuizzes({commit},{uesId,filterQuizDatas = {},studentAllowedPlatforms = [],paginateIndex = 1}){
            try {
                let {status_code,data:{ completedQuizzesGrouped,completedQuizzesGroupedTotalCount} = []} = await axios().post('quiz/get-student-completed-quizzes',{uesId,filterQuizDatas,studentAllowedPlatforms,paginateIndex})
                if(status_code === 200){
                    commit('setStudentCompletedQuizzes',completedQuizzesGrouped)
                    return true;
                }
                else return false
            } catch (error) {
                alert(error)
                return false
            }
           
        },


        async getSelectedConceptQuizzes({commit},filter){
            try {
                let {status_code,data:{ assignedQuizzes = [],totalAssignedQuizzesCount = 0} = []} = await axios().post('quiz/get-concept-quizzes',filter)
                if(status_code === 200){
                    
                    commit('setSelectedConceptQuizzes',assignedQuizzes)
                    commit('setSelectedConceptQuizzesTotalCount',totalAssignedQuizzesCount)
                    return true;
                }
                else return false
            } catch (error) {
                alert(error)
                return false
            }
        }
    },

    getters:{
        getSolvedQuizReportDetails(state){
            return state.completedQuizzes
        },
        getSelectedQuizInfo(state){
                return state.selectedQuizInfo
        },
        getSelectedQuizQuestions(state){
           
            return state.quizQuestions ;
        },

        getAnsweredQuestionCount(state){
            return state.selectedQuizAnswers.length
        },
        getQuizQuestionCount(state){
                return state.quizQuestions.length ;
        },

        getSelectedAnswers(state){
            return state.selectedQuizAnswers ;
    },

        getQuizResult(state){
            return state.quizResult ;
        },

        getQuizStat(state){
            return state.quizStat ;
        },
        getQuizQuestionTimes(state){
            return state.perQuestionsTime ;
        },
        getQuizBannerShowedStatus(state){
           return state.quizBannerShowedStatus   
        },
        getSelectedCompletedQuizId(state){
            return state.selectedCompletedQuizId
        },
        getCurrentGQSubIndex(state){
            return state.currentGQSubIndex;
        },

        getSelectedConceptResource(state){
            return state.selectedConceptResource 
        },
        getSelectedConceptQuizzes(state){
            return state.selectedConceptQuizzes
        },
        getSelectedConceptQuizzesTotalCount(state){
            return state.selectedConceptQuizzesTotalCount
        }


       
    }
}


const allowedRoles = ['student'];
const allowedSchools = ["*"]; // "*" for all schools.
const uesAuthKey = process.env.VUE_APP_AUTH_KEY_MYEDUCONCEPT  || null;
let allowedPlatformsToStudent = [
       'my-edu-concept'
]
const allowedUserRules = {
       roles: allowedRoles,
       schools: allowedSchools,
       allowedPlatformsToStudent,
       uesAuthKey,
       classes: ['1','5','6','7']
}

export default allowedUserRules;
function getQuestionStandarts({role = 'super-admin'}) {

  return [
    {
      key: "cefr",
      label: "CEFR",
    },
    {
      key: "lgs",
      label: "LGS",
    },
    {
      key: "lgs2",
      label: "LGS 2",
    },

    {
      key: "kw3",
      label: "KW3.0",
    },
  
  ]
}

export default {
  questionStandarts:getQuestionStandarts
}
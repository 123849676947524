const allowedRoles = ['student'];
const allowedSchools = ["*"]; // "*" for all schools.
const uesAuthKey = process.env.VUE_APP_UESLMS_AUTH_KEY_LGSXXLARGE || null;
let allowedPlatformsToStudent = [
       'LGSXXLARGE'
]
const allowedUserRules = {
       roles: allowedRoles,
       schools: allowedSchools,
       allowedPlatformsToStudent,
       uesAuthKey
}

export default allowedUserRules;
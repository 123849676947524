<template>
  <div class="warning-container" >

        <span> <i class="fa-solid fa-triangle-exclamation"></i>Are you sure to delete ?</span>
         <span class="close" @click="$emit('closeConfirm')"
      ><i class="fa fa-times" aria-hidden="true"></i
    >
    </span>

    <div class="confirm-button-container">

        <button class="prove-button" @click="$emit('setResponse',true)">
            YES
        </button>

          <button class="cancel-button"  @click="$emit('setResponse',false)">
            NO
        </button>
    </div>

  </div>

   

</template>

<script>
export default {
    props:['warningMessage'],

    created(){
      
        setTimeout(() => {
             this.$emit('closeWarning') ;
        }, 10000);
    }
}
</script>

<style scoped lang="scss">

.warning-container{

    position: fixed;
    top: 25%;
    left: 35%;
    height: 200px;
    width: 40%;
    display: flex;
    align-items: center;
    background: rgba(184, 41, 41, 0.986);
    color: white;
    font-weight: 800;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow:2px 2px 2px  rgba(245, 3, 3, 0.233) ;


    .confirm-button-container{
        margin-top: 50px;
        display: flex;
        justify-content: space-between;


            button {
                padding: 10px;
                font-weight: bold;
                cursor: pointer;
                border-radius: 4px;
             
            }
            .prove-button{

                background: rgb(7, 7, 41);
                color: #fff;

                   &:hover{
                    background: rgb(32, 32, 104);
                }
            }

            .cancel-button {
                     background: rgb(168, 41, 9);
                color: #fff;

                    &:hover{
                    background: rgb(250, 57, 9);
                }
            }

    }

}

.close {
  position: absolute;
  top: 10px;
  right: 21px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;


background: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5 all ease-in-out;
  color:rgba(226, 34, 34, 0.986) ;

  &:hover {
   
    box-shadow: 0.5px 1px 0.5px rgba(225, 230, 225, 0.829);
  }
}
</style>
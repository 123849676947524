
import app from "./app_rules"
import allowedUserRules from "./allowed_user_rules";
import question from "./question_rules";



export default { 
   app,
   allowedUserRules,
   question
}
const getMyStandard = (standardRefOptions,standardName,triggerUpdateFunct,userData,compenentRef) => {
  
       const _this = compenentRef;

       const lgs2Standard = {
        topic: [{
            key: "FRIENDSHIP",
            label: "FRIENDSHIP",
          }]
                            }

    
        _this[standardRefOptions] = lgs2Standard[standardName];
        _this[standardName] = _this[standardRefOptions][0]?.key;
        _this[triggerUpdateFunct](_this[standardRefOptions][0]?.key, standardName);

      
 
}

export default getMyStandard;
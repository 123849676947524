const mebDemoQuestionStandardTypes = [
    {
      key: "lgs",
      label: "LGS",
    },
    {
      key: "lgs2",
      label: "LGS 2",
    },
  ];

export default mebDemoQuestionStandardTypes;


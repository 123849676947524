export default {
    quizResources : [
        {
            key:'none',
            label:'None'
        },
        {
            key:'Concept1',
            label:'Concept 1'
        },
    
    
    ]
}
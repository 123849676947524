import meblgsDemoQuestionStandardType from './meblgsDemoHeadStandard';
import lgsxxlargeDemoQuestionStandardType from './lgsxxlargeDemoHeadStandard';
import gundogduDemoQuestionStandardType from './gundogduDemoHeadStandard';

// LGSXXLARGE school id. If the school is LGSXXLARGE will only show lgs 2 quiz types. 
let lgxxlargeID = process?.env?.VUE_APP_LGSXXLARGE_ID;
// MEB DEMO school id. Only show meb lgs types.
let mebDemoId = process?.env?.VUE_APP_MEBDEMO_ID;

 // Gundogdu school id.
 let gundogduId = process?.env?.VUE_APP_GUNDOGDU_ID ;

const schoolDemoIds = [{
    id: lgxxlargeID,
    questionStandardTypes: lgsxxlargeDemoQuestionStandardType
},
{
    id: mebDemoId,
    questionStandardTypes: meblgsDemoQuestionStandardType
},

{
    id: gundogduId,
    questionStandardTypes: gundogduDemoQuestionStandardType
},

];

const getMyHeadStandart = (standardRef,standardName,triggerUpdateFunct,userData,compenentRef) => {

    const _this = compenentRef ;
    const currentDemoAccount = schoolDemoIds.find(school=>{
        return school.id === _this[userData]?.institutionId
    })

    if(currentDemoAccount){

        _this[standardRef] = currentDemoAccount.questionStandardTypes;
        _this[standardName] = currentDemoAccount.questionStandardTypes[0].key;
        _this[triggerUpdateFunct]( currentDemoAccount.questionStandardTypes[0].key);

    }
}

export default getMyHeadStandart;